import axios from "axios";
import AWS from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
const CryptoJS = require("crypto-js");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const region = "us-east-1";
const identityPoolId = "us-east-1:6d4c0dcf-6d16-4926-bfdd-2eeb475707c0";
const userPoolId = "us-east-1_eBsGlENcD";
const userPoolClientId = "5ce05g97b34oc8llf7uo66t5ru";
const serviceName = "execute-api";
const poolData = { UserPoolId: userPoolId, ClientId: userPoolClientId };
let userPool = "";
let cognitoUser = undefined;

const getCognitoLoginKey = () => {
  return `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
};

const createSignature = (key, stringToSign) => {
  return hexEncode(hmac(key, stringToSign));
};

const hash = (value) => {
  return CryptoJS.SHA256(value);
};

const hashCanonicalRequest = (request) => {
  return hexEncode(hash(request));
};

const buildCanonicalQueryString = (queryParams) => {
  let canonicalQueryParams = "";
  if (queryParams) {
    const entries = Object.entries(queryParams);
    entries &&
      entries.map(([key, value]) => {
        canonicalQueryParams = canonicalQueryParams + key + "=" + value + "&";
      });
    return canonicalQueryParams.substr(0, canonicalQueryParams.length - 1);
  }
  return "";
};

const buildCanonicalHeaders = (headers) => {
  let canonicalHeaders = "";
  const entries = Object.entries(headers);
  entries.map(([key, value]) => {
    canonicalHeaders =
      canonicalHeaders + key.toLocaleLowerCase() + ":" + value + "\n";
  });

  return canonicalHeaders;
};

const buildCanonicalSignedHeaders = (headers) => {
  const signedHeaders = [];
  const keys = Object.keys(headers);
  keys.map((key) => {
    signedHeaders.push(key.toLowerCase());
  });

  return signedHeaders.sort().join(";");
};

const buildCanonicalRequest = (headers, request) => {
  return request === "apikey"
    ? "GET" +
        "\n" +
        encodeURI("/prod/apikey") +
        "\n" +
        buildCanonicalQueryString({ end: undefined, start: undefined }) +
        "\n" +
        buildCanonicalHeaders(headers) +
        "\n" +
        buildCanonicalSignedHeaders(headers) +
        "\n" +
        hexEncode(hash(""))
    : "POST" +
        "\n" +
        encodeURI("/prod/signin") +
        "\n" +
        buildCanonicalQueryString() +
        "\n" +
        buildCanonicalHeaders(headers) +
        "\n" +
        buildCanonicalSignedHeaders(headers) +
        "\n" +
        hexEncode(hash(""));
};

const hexEncode = (value) => {
  return value.toString(CryptoJS.enc.Hex);
};

const hmac = (secret, value) => {
  return CryptoJS.HmacSHA256(value, secret, { asBytes: true });
};

const createHeadersNameArray = (headers) => {
  const headersArray = Object.keys(headers);
  const headersNames =
    headersArray &&
    headersArray.map((header) => {
      return header.toLowerCase();
    });

  return headersNames.sort().join(";");
};

const createCredentialScope = (config) => {
  const date = new Date()
    .toISOString()
    .replace(/\.\d{3}Z$/, "Z")
    .replace(/[:\-]|\.\d{3}/g, "");

  return (
    date.substr(0, 8) +
    "/" +
    config.region +
    "/" +
    serviceName +
    "/" +
    "aws4_request"
  );
};

const createStringToSign = (config, hashedCanonicalRequest) => {
  const date = new Date()
    .toISOString()
    .replace(/\.\d{3}Z$/, "Z")
    .replace(/[:\-]|\.\d{3}/g, "");
  const credentialScope = createCredentialScope(config);

  return (
    "AWS4-HMAC-SHA256" +
    "\n" +
    date +
    "\n" +
    credentialScope +
    "\n" +
    hashedCanonicalRequest
  );
};

const createSigningKey = (config) => {
  const date = new Date()
    .toISOString()
    .replace(/\.\d{3}Z$/, "Z")
    .replace(/[:\-]|\.\d{3}/g, "");

  return hmac(
    hmac(
      hmac(
        hmac("AWS4" + config.credentials.secretAccessKey, date.substr(0, 8)),
        config.region
      ),
      serviceName
    ),
    "aws4_request"
  );
};

const createAuthorizationHeader = (config, headers, request) => {
  const credentialScope = createCredentialScope(config);
  var canonicalRequest = buildCanonicalRequest(headers, request);
  var hashedCanonicalRequest = hashCanonicalRequest(canonicalRequest);
  var stringToSign = createStringToSign(config, hashedCanonicalRequest);
  var signingKey = createSigningKey(config);
  var signature = createSignature(signingKey, stringToSign);
  return (
    "AWS4-HMAC-SHA256 Credential=" +
    config.credentials.accessKeyId +
    "/" +
    credentialScope +
    ", SignedHeaders=" +
    createHeadersNameArray(headers) +
    ", Signature=" +
    signature
  );
};

const createXAmzSecurityToken = (config) => {
  return config && config.credentials && config.credentials.sessionToken;
};

const createXAmzDateHeader = () => {
  return new Date()
    .toISOString()
    .replace(/\.\d{3}Z$/, "Z")
    .replace(/[:\-]|\.\d{3}/g, "");
};

const createSignedHeaders = () => {
  const date = createXAmzDateHeader();

  const headers = {};
  headers["Accept"] = "application/json";
  headers["host"] = "o40fe4ryxi.execute-api.us-east-1.amazonaws.com";
  headers["x-amz-date"] = date;
  return headers;
};

const createHeaders = (config, request) => {
  const headers = createSignedHeaders(config);
  headers["Authorization"] = createAuthorizationHeader(
    config,
    headers,
    request
  );
  headers["x-amz-security-token"] = createXAmzSecurityToken(config);
  return headers;
};

export const initLogin = (callback) => {
  userPool = new CognitoUserPool(poolData);
  cognitoUser = userPool.getCurrentUser();

  if (userPool.getCurrentUser() !== null) {
    userPool.getCurrentUser().getSession(function (err, session) {
      if (err) {
        logOut();
        console.error(err);
        return;
      }
      const cognitoLoginKey = getCognitoLoginKey();
      const Logins = {};
      Logins[cognitoLoginKey] = session.getIdToken().getJwtToken();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: Logins,
      });

      AWS.config.region = region;
      AWS.config.credentials.refresh((error) => {
        if (error) {
          logOut();
          console.error(error);
        } else {
          const config = AWS.config;
          const headersApiKey = createHeaders(config, "apikey");
          headersApiKey["Content-Type"] = "application/json";
          delete headersApiKey["host"];

          const headersSignIn = createHeaders(config, "signin");
          headersSignIn["Content-Type"] = "application/json";
          delete headersSignIn["host"];

          axios({
            method: "POST",
            url:
              "https://o40fe4ryxi.execute-api.us-east-1.amazonaws.com/prod/signin",
            headers: headersSignIn,
          }).then(() => {
            axios({
              method: "GET",
              url:
                "https://o40fe4ryxi.execute-api.us-east-1.amazonaws.com/prod/apikey?end=undefined&start=undefined",
              headers: headersApiKey,
            }).then((response) => {
              response && response.data && callback(response.data.value);
            });
          });
        }
      });
    });
  } else {
    callback();
  }
};

export const login = (email, password) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  let localCognitoUser = new CognitoUser({
    Username: email,
    Pool: new CognitoUserPool(poolData),
  });

  return new Promise((resolve, reject) => {
    localCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser = localCognitoUser;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            [getCognitoLoginKey()]: result.getIdToken().getJwtToken(),
          },
        });
        AWS.config.region = region;
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.error(error);
          } else {
            const config = AWS.config;
            const headersApiKey = createHeaders(config, "apikey");
            headersApiKey["Content-Type"] = "application/json";
            delete headersApiKey["host"];

            const headersSignIn = createHeaders(config, "signin");
            headersSignIn["Content-Type"] = "application/json";
            delete headersSignIn["host"];

            axios({
              method: "POST",
              url:
                "https://o40fe4ryxi.execute-api.us-east-1.amazonaws.com/prod/signin",
              headers: headersSignIn,
            }).then(() => {
              axios({
                method: "GET",
                url:
                  "https://o40fe4ryxi.execute-api.us-east-1.amazonaws.com/prod/apikey?end=undefined&start=undefined",
                headers: headersApiKey,
              }).then((response) => {
                response && response.data && resolve(response.data.value);
              });
            });
          }
        });
      },

      onFailure: (error) => {
        reject(error);
      },
    });
  });
};

export const register = (email, password) => {
  localStorage.clear();
  const attributeList = [];

  const dataEmail = {
    Name: "email",
    Value: email,
  };

  const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
    dataEmail
  );

  attributeList.push(attributeEmail);

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(login(email, password));
      }
    });
  });
};

export const isLoggedIn = () => {
  return cognitoUser;
};

export const logOut = () => {
  if (cognitoUser) {
    cognitoUser.signOut();
    cognitoUser = userPool.getCurrentUser();
    localStorage.clear();
  }
};
