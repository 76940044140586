import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DxcTabs } from "@dxc-technology/halstack-react";
import { findApi } from "./../../services/api-catalog";
import OverviewTab from "./OverviewTab";
import CodeLabsTab from "./CodeLabsTab";
import RelatedApisTab from "./RelatedApisTab";
import ApiReferenceTab from "./ApiReferenceTab";
import { useScreenType } from "../../common/utils";

const ApiDetails = ({ catalog, select }) => {
  const params = useParams();
  const industry = params.industry;
  const domain = params.domain;
  const api = params.api;
  const apiResource = findApi(catalog, industry, domain, api);

  const [activeTab, setActiveTab] = useState(0);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const screenType = useScreenType();

  const setFirstTab = () => {
    setActiveTab(0);
  };

  const renderTabs = (hasOverview, hasCodeLabs) => {
    if (hasOverview && hasCodeLabs) {
      if (activeTab === 0) return <OverviewTab {...apiResource} />;

      if (activeTab === 1) return <ApiReferenceTab api={apiResource} />;

      if (activeTab === 2)
        return (
          <RelatedApisTabContainer onClick={setFirstTab}>
            <RelatedApisTab
              catalog={catalog}
              industry={industry}
              domain={domain}
              api={apiResource}
              select={select}
            />
          </RelatedApisTabContainer>
        );

      if (activeTab === 3) return <CodeLabsTab {...apiResource} />;
    } else if (!hasOverview && hasCodeLabs) {
      if (activeTab === 0) return <ApiReferenceTab api={apiResource} />;

      if (activeTab === 1)
        return (
          <RelatedApisTabContainer onClick={setFirstTab}>
            <RelatedApisTab
              catalog={catalog}
              industry={industry}
              domain={domain}
              api={apiResource}
              select={select}
            />
          </RelatedApisTabContainer>
        );

      if (activeTab === 2) return <CodeLabsTab {...apiResource} />;
    } else if (hasOverview && !hasCodeLabs) {
      if (activeTab === 0) return <OverviewTab {...apiResource} />;

      if (activeTab === 1) return <ApiReferenceTab api={apiResource} />;

      if (activeTab === 2)
        return (
          <RelatedApisTabContainer onClick={setFirstTab}>
            <RelatedApisTab
              catalog={catalog}
              industry={industry}
              domain={domain}
              api={apiResource}
              select={select}
            />
          </RelatedApisTabContainer>
        );
    } else {
      if (activeTab === 0) return <ApiReferenceTab api={apiResource} />;

      if (activeTab === 1)
        return (
          <RelatedApisTabContainer onClick={setFirstTab}>
            <RelatedApisTab
              catalog={catalog}
              industry={industry}
              domain={domain}
              api={apiResource}
              select={select}
            />
          </RelatedApisTabContainer>
        );
    }
  };

  return (
    <ApiDetailsContainer screenType={screenType}>
      {apiResource && (
        <ApiTitle screenType={screenType}>{apiResource.title}</ApiTitle>
      )}
      <TabsContainer>
        {apiResource && apiResource.hasOverview && apiResource.hasCodeLab && (
          <DxcTabs
            activeTabIndex={activeTab}
            onTabClick={onTabClick}
            tabs={[
              {
                label: "Overview",
              },
              {
                label: "API Reference",
              },
              {
                label: "Related APIs",
              },
              {
                label: "Code Labs",
              },
            ]}
          ></DxcTabs>
        )}
        {apiResource && !apiResource.hasOverview && apiResource.hasCodeLab && (
          <DxcTabs
            activeTabIndex={activeTab}
            onTabClick={onTabClick}
            tabs={[
              {
                label: "API Reference",
              },
              {
                label: "Related APIs",
              },
              {
                label: "Code Labs",
              },
            ]}
          ></DxcTabs>
        )}
        {apiResource && apiResource.hasOverview && !apiResource.hasCodeLab && (
          <DxcTabs
            activeTabIndex={activeTab}
            onTabClick={onTabClick}
            tabs={[
              {
                label: "Overview",
              },
              {
                label: "API Reference",
              },
              {
                label: "Related APIs",
              },
            ]}
          ></DxcTabs>
        )}
        {apiResource && !apiResource.hasOverview && !apiResource.hasCodeLab && (
          <DxcTabs
            activeTabIndex={activeTab}
            onTabClick={onTabClick}
            tabs={[
              {
                label: "API Reference",
              },
              {
                label: "Related APIs",
              },
            ]}
          ></DxcTabs>
        )}
      </TabsContainer>

      {apiResource &&
        renderTabs(apiResource.hasOverview, apiResource.hasCodeLab)}
    </ApiDetailsContainer>
  );
};

const RelatedApisTabContainer = styled.div``;

const ApiDetailsContainer = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "80%" : "100%")};
  min-height: 1000px;
  padding-left: ${(props) => (props.screenType === "desktop" ? "148px" : "")};
  padding-top: 0;
`;

const TabsContainer = styled.div`
  padding-bottom: 45px;
`;

const ApiTitle = styled.h3`
  font-size: ${(props) => (props.screenType === "desktop" ? "48px" : "34px")};
  font-weight: 400;
`;

export default ApiDetails;
