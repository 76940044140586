import React, { useState, useEffect, useContext } from "react";
import { getApiResource } from "../../services/api-catalog";
import { DxcSpinner } from "@dxc-technology/halstack-react";
import HalSpec from "./HalSpec";
import SwaggerUI from "./SwaggerSpec";
import SessionContext from "../../SessionContext.js";

const ApiReferenceTab = ({ api }) => {
  const [apiSpec, setApiSpec] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const session = useContext(SessionContext);

  useEffect(() => {
    setIsLoading(true);
    getApiResource(api, "spec", "json")
      .then((spec) => {
        setApiSpec(spec);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        setApiSpec(null);
        setIsLoading(false);
        setError(error);
      });
  }, [api]);

  const isHalApi = (api) => {
    return api && api.definitions && api.definitions.hateoas;
  };

  return (
    <React.Fragment>
      {isLoading && !session.isFetchingSession && (
        <DxcSpinner margin="medium" label="Loading..." mode="overlay" />
      )}
      {apiSpec && isHalApi(apiSpec) && <HalSpec api={apiSpec} />}
      {apiSpec && !isHalApi(apiSpec) && <SwaggerUI api={apiSpec} />}
    </React.Fragment>
  );
};

export default ApiReferenceTab;
