import React, { useContext } from "react";
import styled from "styled-components";
import { DxcTextInput, DxcSpinner } from "@dxc-technology/halstack-react";
import ApiStatus from "../../components/apis/ApiStatus";
import SetUpApiKey from "../../components/apis/SetUpApiKey";
import { useScreenType, useApiStatus } from "../../common/utils";
import SessionContext from "../../SessionContext.js";

const getIndustries = (apis) =>
  apis.reduce(
    (list, api) =>
      !list.find((e) => e.industryTitle === api.industryTitle)
        ? [
            ...list,
            {
              industryTitle: api.industryTitle,
              industryKey: api.industryKey,
            },
          ]
        : list,
    []
  );

const getIndustryDomains = (apis, industryKey) =>
  apis
    .filter((api) => api.industryKey === industryKey)
    .reduce(
      (list, api) =>
        !list.find((e) => e.domainKey === api.domainKey)
          ? [
              ...list,
              {
                domainTitle: api.domainTitle,
                domainKey: api.domainKey,
              },
            ]
          : list,
      []
    );

const getDomainApis = (apis, domainKey, industryKey) =>
  apis.filter(
    (api) => api.domainKey === domainKey && api.industryKey === industryKey
  );
const statuses = [
  { text: "Reachable", color: "#CBF89C" },
  { text: "Not Well Formed", color: "#FFF352" },
  { text: "Not Hypermedia", color: "#E5E5E5" },
  { text: "Not Reachable", color: "#F99393" },
];

const Status = () => {
  const {
    filteredCatalog,
    searchValue,
    isLoading,
    apiKey,
    setSearchValue,
    setApiKey,
  } = useApiStatus();
  const screenType = useScreenType();
  const session = useContext(SessionContext);

  return (
    <StatusContainer>
      {isLoading || session.isFetchingSession ? (
        <DxcSpinner label="Loading..." mode="overlay" />
      ) : apiKey && !session.isFetchingSession ? (
        <PageContent>
          <DxcTextInput
            label="Search for an API..."
            value={searchValue.value}
            onChange={setSearchValue}
            size="fillParent"
            clearable
            margin={{
              top: "medium",
              bottom: "small",
            }}
          />
          <TitleContainer screenType={screenType}>
            API Status Console
          </TitleContainer>
          <StatusDescriptionContainer screenType={screenType}>
            {statuses.map((status, index) => (
              <StatusDescription key={`status-description-${index}`}>
                <StatusColor color={status.color} />
                <StatusName>{status.text}</StatusName>
              </StatusDescription>
            ))}
          </StatusDescriptionContainer>
          {(!isLoading || !session.isFetchingSession) &&
          filteredCatalog.length === 0 ? (
            <ResultMessage>APIs not found</ResultMessage>
          ) : (
            getIndustries(filteredCatalog).map((industry, index) => (
              <IndustryAPIsContainer key={`industry-${index}`}>
                <IndustryTitle>{industry.industryTitle}</IndustryTitle>
                {getIndustryDomains(filteredCatalog, industry.industryKey).map(
                  (domain, index) => (
                    <DomainContainer key={`industry-domain-${index}`}>
                      <DomainTitle>{domain.domainTitle}</DomainTitle>
                      {getDomainApis(
                        filteredCatalog,
                        domain.domainKey,
                        industry.industryKey
                      ).map((api, index) => (
                        <ApiStatus
                          key={`api-status-${index}`}
                          status={api.status}
                          title={api.title}
                        />
                      ))}
                    </DomainContainer>
                  )
                )}
              </IndustryAPIsContainer>
            ))
          )}
        </PageContent>
      ) : (
        !apiKey &&
        !session.isFetchingSession && (
          <PageContent marginTop={true}>
            <SetUpApiKey onSetApiKey={setApiKey} />
          </PageContent>
        )
      )}
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  border-top: 40px solid #d9d9d9;
`;

const PageContent = styled.div`
  margin: 40px 10%;
`;

const TitleContainer = styled.div`
  font-size: ${(props) =>
    props.screenType === "tablet"
      ? "36px"
      : props.screenType === "mobile"
      ? "32px"
      : "48px"};
  letter-spacing: 0px;
  margin-bottom: 20px;
`;

const StatusDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.screenType === "mobile" ? "column" : "")};
  justify-content: flex-start;
`;

const StatusDescription = styled.div`
  display: flex;
  align-items: baseline;
`;

const StatusColor = styled.div`
  border-radius: 45px;
  width: 10px;
  height: 10px;
  background: ${(props) => props.color};
  margin: 5px;
`;

const StatusName = styled.div`
  font: normal 12px/17px Open Sans;
  letter-spacing: 1.88px;
  color: #000000;
  text-transform: uppercase;
  margin: 0 30px 0 0;
`;

const IndustryAPIsContainer = styled.div``;

const IndustryTitle = styled.div`
  text-transform: uppercase;
  font: normal 20px/27px Open Sans;
  letter-spacing: 0.26px;
  color: #000000de;
  margin-top: 50px;
`;

const DomainContainer = styled.div`
  margin-top: 40px;
`;

const DomainTitle = styled.div`
  font: normal 16px/22px Open Sans;
  letter-spacing: 0.49px;
  color: #000000;
  margin: 15px 0;
`;

const ResultMessage = styled.div`
  text-transform: uppercase;
  font: normal 20px/27px Open Sans;
  letter-spacing: 0.26px;
  color: #000000de;
  margin-top: 50px;
  text-align: center;
`;

export default Status;
