import React from "react";
import styled from "styled-components";
import { typeface } from "../common/variables.js";
import { useScreenType } from "../common/utils";
import codeExample from "./images/code.svg";

const Developer = () => {
  const screenType = useScreenType();

  return (
    <StyledDeveloper screenType={screenType}>
      <DevelopersFirst screenType={screenType}>
        <DevelopersFirstContent>
          <DevelopersFirstTitle screenType={screenType}>
            From developers to developers
          </DevelopersFirstTitle>
          <DevelopersSubitle screenType={screenType}>
            Bring your creativity and passion with you and start creating new
            products. Like you, we want to leave a footprint in the digital era.
            We are starting by making DXC Insurance APIs and data available.
          </DevelopersSubitle>
          <CodeImage src={codeExample}></CodeImage>
        </DevelopersFirstContent>
      </DevelopersFirst>
      {/* <DeveloperDescription screenType={screenType}>
        <DeveloperDescriptionContent screenType={screenType}>
          <DeveloperDescriptionTitle>
            Not a developer?
          </DeveloperDescriptionTitle>
          <DeveloperDescriptionText screenType={screenType}>
            You still can find useful information about DXC's approach to
            innovation with hypermedia APIs. We have prepared a rich set of
            documentation for you.
          </DeveloperDescriptionText>
          <DxcButtonContainer>
            <DxcButton
              mode="basic"
              label="Start Learning"
              onClick={onClick}
              size="fitContent"
            />
          </DxcButtonContainer>
        </DeveloperDescriptionContent>
      </DeveloperDescription> */}
    </StyledDeveloper>
  );
};

const StyledDeveloper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "mobile" ? "column" : "row"};
  margin-bottom: ${(props) =>
    props.screenType === "desktop" ? "200px" : "20px"};
  padding: ${(props) =>
    props.screenType === "mobile"
      ? "80px 40px 100px 40px"
      : props.screenType === "tablet"
      ? "80px 80px 64px 80px"
      : ""};
  ${(props) => (props.screenType === "tablet" ? "flex-wrap: wrap" : "")};
  ${(props) => (props.screenType === "desktop" ? "align-items: baseline" : "")};
`;

const DevelopersFirst = styled.div`
  width: ${(props) => (props.screenType !== "desktop" ? "100%" : "60%")};
  display: flex;
  padding-top: ${(props) => (props.screenType === "desktop" ? "125px" : "")};
  padding-left: ${(props) => (props.screenType === "desktop" ? "10%" : "")};
`;

const DevelopersFirstContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const DevelopersFirstTitle = styled.div`
  font-size: ${(props) => (props.screenType !== "mobile" ? "48px" : "34px")};
  letter-spacing: ${(props) =>
    props.screenType !== "mobile" ? "0" : "0.25px"};
  margin-bottom: 40px;
  color: black;
  justify-content: start;
  text-align: ${(props) => (props.screenType === "mobile" ? "center" : "")};
  font-weight: ${(props) => (props.screenType === "mobile" ? "500" : "")};
`;

const DevelopersSubitle = styled.div`
  width: ${(props) => (props.screenType !== "mobile" ? "69%" : "100")};
  font-size: ${typeface.body.fontSize};
  letter-spacing: ${typeface.body.letterSpacing};
  text-transform: ${typeface.body.textTransform};
  margin-bottom: 20px;
`;

const CodeImage = styled.img`
  align-self: flex-start;
  width: ${(props) => (props.screenType !== "desktop" ? "100%" : "auto")};
`;

export default Developer;
