import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import insurance from "./images/insurance.png";
import workplace from "./images/workplace.png";
import healthcare from "./images/healthcare.png";
import externalservices from "./images/external.png";

import { useScreenType } from "../common/utils";

const IndustriesSummary = () => {
  const screenType = useScreenType();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: `${screenType !== "desktop" ? "auto" : "410px"}`,
        position: `${screenType === "tablet" ? "relative" : ""}`,
      }}
    >
      <StyledIndustries screenType={screenType}>
        {screenType !== "desktop" && (
          <IndustriesTitleMobile>
            <p>Products</p>
          </IndustriesTitleMobile>
        )}
        <Link
          to="/apis/catalog?industry=insurance"
          style={{ textDecoration: "none" }}
        >
          <Industry screenType={screenType}>
            <IndustryImage
              src={insurance}
              alt="Insurance Icon"
              screenType={screenType}
            />
            <IndustryName screenType={screenType}>Insurance</IndustryName>
          </Industry>
        </Link>
        <Link
          to="/apis/catalog?industry=workplace-and-mobility"
          style={{ textDecoration: "none" }}
        >
          <Industry screenType={screenType}>
            <IndustryImage
              src={workplace}
              alt="Workplace and Mobility Icon"
              screenType={screenType}
            />

            <IndustryName screenType={screenType}>
              Workplace and Mobility
            </IndustryName>
          </Industry>
        </Link>
        <Link
          to="/apis/catalog?industry=healthcare"
          style={{ textDecoration: "none" }}
        >
          <Industry screenType={screenType}>
            <IndustryImage
              src={healthcare}
              alt="Healthcare Icon"
              screenType={screenType}
            />

            <IndustryName screenType={screenType}>Healthcare</IndustryName>
          </Industry>
        </Link>
        <Link
          to="/apis/catalog?industry=external-services"
          style={{ textDecoration: "none" }}
        >
          <Industry screenType={screenType}>
            <IndustryImage
              src={externalservices}
              alt="External Services Icon"
              screenType={screenType}
            />
            <IndustryName screenType={screenType}>
              External Services
            </IndustryName>
          </Industry>
        </Link>
      </StyledIndustries>
    </div>
  );
};

const StyledIndustries = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "mobile" ? "column" : "row"};
  justify-content: ${(props) =>
    props.screenType === "tablet" ? "space-between" : "center"};
  align-items: ${(props) =>
    props.screenType === "mobile" ? "center" : "baseline"};
  flex-wrap: ${(props) => (props.screenType === "tablet" ? "wrap" : "nowrap")};
  width: 100%;
  ${(props) =>
    props.screenType === "mobile"
      ? "padding: 100px 0"
      : props.screenType === "tablet"
      ? "padding:  130px 90px 80px 90px"
      : ""};
  position: ${(props) => (props.screenType === "mobile" ? "relative" : "")};
  overflow: hidden;

  a {
    display: inline-flex;
    justify-content: center;
    width: ${(props) => (props.screenType === "tablet" ? "50%" : "")};
  }
`;

const Industry = styled.div`
  margin: ${(props) => (props.screenType !== "tablet" ? "0 60px" : "0")};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 168px;
  padding: 10px;
  & img {
    max-height: 100px;
    align-self: center;
  }
`;

const IndustryImage = styled.img`
  height: ${(props) =>
    props.screenType === "mobile"
      ? "68px"
      : props.screenType === "tablet"
      ? "84px"
      : "92px"};
  width: ${(props) =>
    props.screenType === "mobile"
      ? "68px"
      : props.screenType === "tablet"
      ? "84px"
      : "92px"};
`;

const IndustryName = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-size: ${(props) =>
    props.screenType === "mobile"
      ? "18px"
      : props.screenType === "tablet"
      ? "22px"
      : "24px"};
  letter-spacing: 0px;
  color: ${(props) => (props.screenType === "mobile" ? "#00000099" : "#000")};
  margin-top: 16px;
`;

const IndustriesTitleMobile = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  padding: 0 40px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  p {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.88px;
    color: #00000099;
  }
`;

export default IndustriesSummary;
