import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DxcApplicationLayout } from "@dxc-technology/halstack-react";
import linkedinLogo from "./images/linkedin.svg";
import twitterLogo from "./images/twitter.svg";
import facebookLogo from "./images/facebook.svg";
import ScrollToTop from "./common/ScrollToTop";
import Header from "./common/Header";
import Dashboard from "./common/Dashboard";
import FooterContent from "./common/FooterContent";
import Home from "./Home/Home";
import Overview from "./DesignSystem/Overview/Overview";
import Help from "./DesignSystem/Help/Help";

import ToolsDesign from "./DesignSystem/Tools/Tools";
import GuidelinesApi from "./pages/apis/GuidelinesApis";
import SidenavApiCatalog from "./pages/apis/Sidenav";
import ApiCatalog from "./pages/apis/ApiCatalog";
import ToolsApis from "./pages/apis/Tools";
import { isLoggedIn } from "./services/login.js";
import GettingStarted from "./pages/apis/GettingStarted";
import Status from "./pages/apis/Status";
import { initLogin } from "./services/login.js";
import SessionContext from "./SessionContext.js";
import ApiCatalogContext from "./ApiCatalogContext";
import Login from "./common/Login";
import ComponentGallery from "./DesignSystem/Overview/ComponentGallery";

const useSession = () => {
  const [apiKey, setApiKey] = useState(null);
  const [username, setUsername] = useState(null);
  const [isFetchingSession, setIsFetchingSession] = useState(false);

  useEffect(() => {
    setIsFetchingSession(true);
    initLogin((session) => {
      if (session) {
        setApiKey(session);
        isLoggedIn() && setUsername(isLoggedIn().username);
      }
      setIsFetchingSession(false);
    });
  }, []);

  const resetSession = () => {
    setApiKey(null);
    setUsername(null);
  };

  return {
    apiKey,
    username,
    isFetchingSession,
    setApiKey,
    setUsername,
    resetSession,
  };
};

function App() {
  const session = useSession();
  const location = useLocation();
  const [searchString, setSearchString] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [dialogLogin, setDialogLogin] = useState({
    isLoginVisible: false,
    isRegisterVisible: false,
  });

  const hasSidenav = !location?.pathname?.includes("/apis/catalog");

  const handleLogin = () => {
    setDialogLogin({
      isLoginVisible: !dialogLogin.isLoginVisible,
      isRegisterVisible: false,
    });
  };

  const handleRegister = () => {
    setDialogLogin({
      isLoginVisible: false,
      isRegisterVisible: !dialogLogin.isRegisterVisible,
    });
  };

  return (
    <SessionContext.Provider value={session}>
      <ApiCatalogContext.Provider
        value={{
          searchString,
          setSearchString,
          selectedRegion,
          setSelectedRegion,
        }}
      >
        <ScrollToTop />
        <DxcApplicationLayout
          visibilityToggleLabel="Menu"
          sidenav={
            !hasSidenav && (
              <>
                {location?.pathname?.includes("/apis/catalog") && (
                  <DxcApplicationLayout.SideNav
                    children={<SidenavApiCatalog />}
                  />
                )}
              </>
            )
          }
          header={
            <Header handleLogin={handleLogin} handleRegister={handleRegister} />
          }
          footer={
            <DxcApplicationLayout.Footer
              bottomLinks={[
                {
                  href: "https://www.dxc.technology",
                  text: "DXC Technology",
                },
                {
                  href: "https://www.dxc.technology/privacy",
                  text: "Privacy",
                },
                {
                  href: "https://www.dxc.technology/legal",
                  text: "Terms",
                },
              ]}
              socialLinks={[
                {
                  href: "https://www.linkedin.com/company/dxctechnology",
                  logo: linkedinLogo,
                },
                {
                  href: "https://twitter.com/dxctechnology",
                  logo: twitterLogo,
                },
                {
                  href: "https://www.facebook.com/DXCTechnology/",
                  logo: facebookLogo,
                },
              ]}
            >
              <FooterContent />
            </DxcApplicationLayout.Footer>
          }
        >
          <DxcApplicationLayout.Main>
            {dialogLogin.isLoginVisible && <Login handleLogin={handleLogin} />}
            {dialogLogin.isRegisterVisible && (
              <Login handleRegister={handleRegister} />
            )}
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/getting-started">
                <GettingStarted />
              </Route>
              <Route exact path="/design">
                <Overview />
              </Route>
              <Route exact path="/design/component-gallery">
                <ComponentGallery />
              </Route>
              <Route path="/design/help">
                <Help />
              </Route>
              <Route path="/design/tools">
                <ToolsDesign />
              </Route>
              <Route exact path="/apis">
                <GuidelinesApi />
              </Route>
              <Route path="/apis/catalog">
                <ApiCatalog />
              </Route>
              <Route path="/apis/tools">
                <ToolsApis />
              </Route>
              <Route path="/apis/status">
                <Status />
              </Route>
              <Route component={() => <h2>Page not found</h2>} />
            </Switch>
          </DxcApplicationLayout.Main>
        </DxcApplicationLayout>
      </ApiCatalogContext.Provider>
    </SessionContext.Provider>
  );
}

export default App;
