import React from "react";
import styled from "styled-components";
import html5 from "./images/html5.svg";
import css3 from "./images/html3.svg";
import js from "./images/js.svg";
import react from "./images/react.svg";

import { useScreenType } from "../../common/utils";
import { DxcHeading } from "@dxc-technology/halstack-react";

const Technologies = () => {
  const screenType = useScreenType();

  return (
    <TechnologiesContainer screenType={screenType}>
      <DxcHeading level={1} text="Technologies" weight="normal" />
      <IconsContainer screenType={screenType}>
        <FirstIconContainer screenType={screenType}>
          <img src={html5} alt="html-icon" />
        </FirstIconContainer>
        {screenType !== "mobile" && (
          <div>
            <img src={css3} alt="css3-icon" />
          </div>
        )}
        <div>
          <img src={js} alt="js-icon" />
        </div>
        <LastIconContainer screenType={screenType}>
          <img src={react} alt="react-icon" />
        </LastIconContainer>
      </IconsContainer>
    </TechnologiesContainer>
  );
};

const TechnologiesContainer = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "70%" : "100%")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.screenType === "tablet"
      ? "margin: 0 16%; width: calc(100% - 32%);"
      : ""};
  ${(props) =>
    props.screenType === "mobile"
      ? "margin: 0 40px; width: calc(100% - 80px);"
      : ""};
`;
const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 70px;
  margin-bottom: ${(props) =>
    props.screenType === "tablet"
      ? "90px"
      : props.screenType === "mobile"
      ? ""
      : "200px"};
  flex-wrap: ${(props) => (props.screenType === "mobile" ? "wrap" : "")};

  & img {
    width: 64px;
  }

  & > div {
    ${(props) =>
      props.screenType === "mobile"
        ? "width: 50%; text-align: center; margin-bottom: 60px;"
        : ""};
  }
`;
const FirstIconContainer = styled.div`
  margin-left: ${(props) => (props.screenType !== "desktop" ? "" : "20%")};
`;
const LastIconContainer = styled.div`
  margin-right: ${(props) => (props.screenType !== "desktop" ? "" : "20%")};
  img {
    width: 50px;
  }
`;

export default Technologies;
