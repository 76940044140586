import React from "react";
import styled from "styled-components";
import {
  DxcQuickNav,
  DxcLink,
  DxcInset,
  DxcFlex,
} from "@dxc-technology/halstack-react";
import { useScreenType } from "../../common/utils";

const GettingStarted = () => {
  const screenType = useScreenType();

  const links = [
    {
      label: "Knowing DXC Developer Central",
    },
    {
      label: "First Steps",
    },
    {
      label: "Security and Authentication",
    },
    {
      label: "Next Steps",
    },
  ];

  return (
    <GettingStartedContent screenType={screenType}>
      <ContentTitle>Your Innovation Journey Starts Here.</ContentTitle>
      <Introduction>
        <Paragraph style={{ padding: "0" }}>
          What product do you have in mind? Bring your ideas and start building
          omni-channel apps using our REST APIs, tools, and other resources from
          our Digital Insurance Platform. You have full power to start creating
          business solutions and help our network of global customers thrive on
          the digital change.
        </Paragraph>
      </Introduction>

      <DxcInset>
        <DxcFlex direction="row">
          <DxcFlex direction="column" gap="2rem">
            <div id="knowing-dxc-developer-central">
              <SectionTitle>Knowing DXC Developer Central</SectionTitle>
              <Paragraph>
                <DxcLink href="https://www.dxc.technology/insurance/offerings/11096-insurance_products_and_services">
                  Product
                </DxcLink>
                <span> and </span>
                <DxcLink href="https://thrive.dxc.technology/">
                  Community
                </DxcLink>
                . This is DXC's strategy to leave a footprint in the digital
                era. And the developer portal is an angular piece of this
                vision. At DXC, we believe APIs are finalized products and, as
                such, they must be made available to consumers. This portal
                offers tools and resources to connect the development community
                with DXC's API provider teams. We are exposing our APIs and we
                want to educate developers to use them, build new products on
                top of them, and share their Innovation and creativity with us.
              </Paragraph>
              <Paragraph>
                These are the resources and tools available to developers like
                you registering in DXC Developer Central:
              </Paragraph>
              <List>
                <li>
                  <strong>Discover</strong>: Register in DXC Developer Central
                  to get access to our Developers Guide and learn how to consume
                  DXC REST APIs. Connect with other developers, learn from our
                  Code Labs, and propose new features. Also, find out what is
                  possible by looking at our reference omni-channel apps. We
                  have organized some featured content so it is easier for you
                  to find what you are looking for.
                </li>
                <br />
                <li>
                  <strong>Build</strong>: We have published a great{" "}
                  <DxcLink href={"/apis/catalog"}>REST API Catalog</DxcLink> for
                  you. Here you will get access to the different operations
                  available through publicly-exposed interfaces. Navigate
                  through the API test console for both spec-driven and
                  hypermedia-driven APIs, learn about our REST resource
                  representations and see our live APIs working. Get your own
                  API key by signing-in the portal and after that, we are giving
                  you full power to unleash your creativity.
                </li>
              </List>
            </div>
            <div id="first-steps">
              <SectionTitle>First Steps</SectionTitle>
              <Paragraph>
                You are at the right place. And at the right moment. We have
                carefully designed a developers portal journey so you can start
                turning your ideas into reality and build apps using DXC REST
                APIs. These are the resources at hand.
              </Paragraph>
              <SubSectionTitle>
                Register at DXC Developer Central
              </SubSectionTitle>
              <Paragraph>
                Make sure you get yourself registered in the portal if you want
                to access all DXC API development resources.
              </Paragraph>
              <SubSectionTitle>
                Reading our Development Tutorials
              </SubSectionTitle>
              <Paragraph>
                There are vast reading resources at DXC Developer Central. Of
                course, this getting started document is fundamental, but there
                are a lot of tutorials available to you on how to build
                omni-channel apps using our REST APIs.
              </Paragraph>
              <List>
                <li>
                  <strong>Developers Guide</strong>: Dig into the general
                  documentation we have written for the development community on
                  the purpose of DXC REST APIs and how to consume them. Here
                  you'll find guidance around tools, patterns, and other useful
                  resources to build your own apps.
                </li>
                <br />
                <li>
                  <strong>API Overview</strong>: Every API is documented with
                  general documentation about its main purpose, structure of the
                  resources, and what business functionality and data is exposed
                  through the API. This overview hill help you understand every
                  API before you start coding!
                </li>
                <br />
                <li>
                  <strong>API Codelabs</strong>: We have prepared a few
                  pre-defined coding scenarios only available for registered
                  users, with instructions on how to build sample apps consuming
                  our REST APIs. Although we know you don't need it!
                </li>
                <br />
                <li>
                  <strong>Blog</strong>: Do you want to hear from our Digital
                  Insurance Platform technical team? Here is where we will
                  communicate new features, releases and our overall thought
                  process around REST APIs and microservices.
                </li>
              </List>
              <SubSectionTitle>
                Try our API Catalog in the Test Console
              </SubSectionTitle>
              <Paragraph>
                Our{" "}
                <DxcLink href={"/apis/catalog"}>catalog of REST APIs</DxcLink>{" "}
                offers publicly-available endpoints for developers. You just
                need to get registered in DXC Developer Central and you will be
                able to get API keys for each and every catalog. The test
                console is a very useful tool to understand what resources are
                exposed, what operations are available and what is the structure
                of our API response using JSON HAL. So before rolling up your
                sleeves and facing your blank-paged IDE, navigate through the
                test console so you can try things out during your design
                process. Also, each API is accompanied by overview documentation
                and consumption examples using most common programming
                languages.
              </Paragraph>
              <SubSectionTitle>
                Take a look at our Reference Apps
              </SubSectionTitle>
              <Paragraph>
                We have developed a bunch of demo reference apps on the Digital
                Insurance Platform using DXC REST APIs. With great power comes
                great responsibility, so this set of apps will help you
                understand the available opportunities. Personae-based
                workflows, Single Page Applications (SPAs), conversational bots
                through mobile and voice channel, etc. There are a few examples
                for you to look into.
              </Paragraph>
            </div>
            <div id="security-and-authentication">
              <SectionTitle>Security and Authentication</SectionTitle>

              <Paragraph>
                DXC Developer Central portal allows self-registration through
                email verification. Upon registration, you will be able to
                access the API catalog, browse spec-driven and hypermedia-driven
                APIs and obtain your own API key to start coding apps.
              </Paragraph>

              <Paragraph>
                The steps in the default registration process are:
              </Paragraph>
              <List>
                <li>
                  You can self-register to DXC Developer Central by following
                  the <strong>Register</strong> button at the top right of the
                  page.
                </li>
                <br />
                <li>
                  Once you provide a user name (email) and password, the system
                  will send you an email with a clickable link in order to
                  verify your address.
                </li>
                <br />
                <li>
                  Upon email validation, you will be able to Sign In providing
                  your credentials.
                </li>
              </List>
            </div>
            <div id="next-steps">
              <SectionTitle>Next Steps</SectionTitle>
              <Paragraph>
                Unleash your innovation and start creating new Apps with DXC
                REST APIs:
              </Paragraph>
              <List>
                <li>
                  As an authenticated user, you will get access to featured
                  content and be able to browse{" "}
                  <DxcLink href={"/apis/catalog"}>DXC's API catalog</DxcLink>.
                  Additionally, you will get acces to your own API key. This key
                  is only available after sign in.
                </li>
                <li>
                  You can pass the API key in your code when making requests to
                  DXC APIs.
                </li>
              </List>
            </div>
          </DxcFlex>
          <QuickNavContainer screenType={screenType}>
            <DxcQuickNav links={links}></DxcQuickNav>
          </QuickNavContainer>
        </DxcFlex>
      </DxcInset>
    </GettingStartedContent>
  );
};

const GettingStartedContent = styled.div`
  margin: ${(props) =>
    props.screenType === "desktop"
      ? "110px 150px"
      : props.screenType === "tablet"
      ? "0 80px"
      : "0 40px"};
`;
const Introduction = styled.div`
  padding-bottom: 50px;
`;

const ContentTitle = styled.h4`
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-weight: 300;
  margin-bottom: 32px;
`;

const SectionTitle = styled.h3`
  margin: 64px 0 34px 0;
  color: #000000;
  letter-spacing: 0px;
  font-size: 24px;
  font-weight: 400;
`;

const SubSectionTitle = styled.h4`
  padding: 0px 2%;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #666666;
  letter-spacing: 0.49px;
  padding: 0px 2%;
  word-break: break-word;
`;

const List = styled.ul`
  padding: 16px 6%;
  li {
    color: #666;
    line-height: 22px;
  }
`;

const QuickNavContainer = styled.div`
  position: sticky;
  margin-top: 64px;
  margin-left: 24px;
  max-width: 300px;
  top: calc(64px + 24px);
  max-height: calc(100vh - 64px);
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #66666626;
    border-radius: 3px;
  }

  ${(props) => props.screenType !== "desktop" && "display: none;"};
`;

export default GettingStarted;
