import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

import carouselItem1 from "./images/office-2.jpg";
import carouselItem3 from "./images/design.jpg";
import carouselItem2 from "./images/code-unsplash.jpg";
import arrowLeft from "../common/images/arrow-left.svg";
import arrowRight from "../common/images/arrow-right.svg";

import { useScreenType } from "../common/utils";

const Carousel = () => {
  const screenType = useScreenType();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8500,
    nextArrow: (
      <ArrowWrapper position="right" screenType={screenType}>
        <RightArrow src={arrowRight} screenType={screenType} />
      </ArrowWrapper>
    ),
    prevArrow: (
      <ArrowWrapper position="left" screenType={screenType}>
        <LeftArrow src={arrowLeft} screenType={screenType} />
      </ArrowWrapper>
    ),
  };

  return (
    <SliderContainer screenType={screenType}>
      <Slider {...sliderSettings}>
        <Item>
          <FirstItemContent></FirstItemContent>
          <SloganContainer screenType={screenType}>
            <SloganTitle screenType={screenType}>DXC Invitational</SloganTitle>
            <SloganSubtitle screenType={screenType}>
              Welcome insurtechs
            </SloganSubtitle>
          </SloganContainer>
        </Item>
        <Item>
          <SecondItemContent></SecondItemContent>
          <SloganContainer screenType={screenType}>
            <SloganTitle screenType={screenType}>
              Halstack Design System
            </SloganTitle>
            <SloganSubtitle screenType={screenType}>
              Bring applications to life
            </SloganSubtitle>
          </SloganContainer>
        </Item>
        <Item>
          <ThirdItemContent></ThirdItemContent>
          <SloganContainer screenType={screenType}>
            <SloganTitle screenType={screenType}>
              Explore. Observe. Imagine.
            </SloganTitle>
            <SloganSubtitle screenType={screenType}>
              Welcome developers
            </SloganSubtitle>
          </SloganContainer>
        </Item>
      </Slider>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  height: ${(props) => (props.screenType === "mobile" ? "64vh" : "60vh")};
  .slick-slider {
    height: 100%;
  }

  .slick-slider {
    backface-visibility: hidden;
  }

  .slick-slide {
    div:first-child {
      height: 100%;
    }
  }

  .slick-slider button.slick-prev,
  .slick-slider button.slick-next {
    display: none !important;
  }

  .slick-dots {
    width: 40%;
    bottom: ${(props) => (props.screenType !== "desktop" ? "40px" : "60px")};
    right: 30%;
    left: unset;
  }

  .slick-dots li.slick-active button:before {
    color: #6f2c91;
  }

  .slick-dots li {
    margin: 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    font-size: 16px;
    color: white;
    opacity: 0.6;
  }

  .slick-slide,
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
  }
`;

const Item = styled.div`
  height: 100%;
  width: 100%;
  display: block !important;
`;

const FirstItemContent = styled.div`
  background-image: url(${carouselItem1});
  background-repeat: no-repeat;
  background-position-y: 46%;
  background-size: cover;
  height: calc(75vh - 80px);
  width: 100%;
  position: relative;
  & svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const ArrowWrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: ${(props) => (props.screenType !== "desktop" ? "50px" : "35px")};
  background-color: lightgrey;
  display: flex;
  top: unset;
  bottom: ${(props) => (props.screenType !== "desktop" ? "0" : "36px")};
  left: unset;
  right: ${(props) =>
    props.position === "left" && props.screenType !== "desktop"
      ? "unset"
      : props.position === "left" && props.screenType === "desktop"
      ? "110px"
      : props.position === "right" && props.screenType !== "desktop"
      ? "20px"
      : "60px"};
  left: ${(props) =>
    props.position === "left" && props.screenType !== "desktop"
      ? "20px"
      : props.position === "left" && props.screenType === "desktop"
      ? "unset"
      : "unset"};
  opacity: 0.8;
  z-index: 1;
  ${(props) =>
    props.position === "left"
      ? "border-bottom-left-radius: 50px"
      : "border-bottom-right-radius: 50px"};
  ${(props) =>
    props.position === "left"
      ? "border-top-left-radius: 50px"
      : "border-top-right-radius: 50px"};
  ${(props) => (props.screenType !== "desktop" ? "border-radius: 50px" : "")};

  &:hover {
    background-color: lightgrey;
    cursor: pointer;
  }

  &:before {
    display: none;
  }
`;

const LeftArrow = styled.img`
  width: ${(props) =>
    props.screenType === "tablet"
      ? "20px"
      : props.screenType === "mobile"
      ? "16px"
      : ""};
`;

const RightArrow = styled.img`
  width: ${(props) =>
    props.screenType === "tablet"
      ? "20px"
      : props.screenType === "mobile"
      ? "16px"
      : ""};
`;

const SecondItemContent = styled.div`
  background-image: url(${carouselItem2});
  background-repeat: no-repeat;
  background-position-y: 36%;
  background-size: cover;
  height: calc(75vh - 80px);
  width: 100%;
  position: relative;
  & svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const ThirdItemContent = styled.div`
  background-image: url(${carouselItem3});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(75vh - 80px);
  width: 100%;
  position: relative;
  & svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const SloganContainer = styled.div`
  position: absolute;
  right: ${(props) =>
    props.screenType === "mobile" ? "calc(50% - 129px)" : "10%"};
  left: 4%;
  bottom: 20%;
  display: flex;
  flex-direction: column;
  line-height: 55px;
  z-index: 2;
`;

const SloganTitle = styled.div`
  color: white;
  font-size: ${(props) => (props.screenType !== "desktop" ? "34px" : "48px")};
  letter-spacing: ${(props) =>
    props.screenType !== "desktop" ? "0.24px" : "0"};
  text-align: ${(props) =>
    props.screenType !== "desktop" ? "center" : "left"};
`;

const SloganSubtitle = styled.div`
  font-size: ${(props) => (props.screenType !== "desktop" ? "24px" : "34px")};
  letter-spacing: 0.25px;
  color: #6f2c91;
  font-weight: 600;
  text-align: ${(props) =>
    props.screenType !== "desktop" ? "center" : "left"};
`;

export default Carousel;
