import React, { useRef } from "react";
import styled from "styled-components";
import Carousel from "./Carousel";
import IndustriesSummary from "./IndustriesSummary";
import Discover from "./Discover";
import Build from "./Build";
import Developer from "./Developer";
import arrowDown from "./images/arrow-down.svg";
import { useScreenType } from "../common/utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  const ref = useRef(null);
  const screenType = useScreenType();

  const handleClick = () => {
    if (ref && ref.current) {
      window.scroll({
        top: ref.current.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div style={{ position: "relative" }}>
          {screenType === "desktop" && (
            <ArrowContainer onClick={handleClick}>
              
              <img src={arrowDown} alt="down arrow" />
              <p
                style={{
                  textTransform: "uppercase",
                  fontSize: "10px",
                  position: "absolute",
                  top: "38px",
                  fontWeight: 600
                }}
              >
                Scroll
              </p>
            </ArrowContainer>
          )}
          <Carousel />
        </div>
      </React.Fragment>
      <div ref={ref}>
        <IndustriesSummary />
        <Discover />
        <Build />
        <Developer />
      </div>
    </React.Fragment>
  );
};

export default HomePage;

const ArrowContainer = styled.div`
  width: 42px;
  height: 42px;
  background-color: lightgrey;
  border-radius: 50%;
  position: absolute;
  bottom: -21px;
  left: calc(50% - 21px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  opacity: 0.8;
  cursor: pointer;
`;
