import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { getApiResource } from "./../../services/api-catalog";
import { DxcSpinner } from "@dxc-technology/halstack-react";

const OverviewTab = apiResource => {
  const [md, setMd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getApiResource(apiResource, "overview", "md")
      .then(md => {
        setMd(md);
        setIsLoading(false);
        setError(null);
      })
      .catch(error => {
        setMd(null);
        setIsLoading(false);
        setError(error);
      });
  }, [apiResource]);

  const transformImageUri = api => {
    return uri =>
      new URL(
        uri,
        `https://developer.dxc.com/${api.path}`
      ).href;
  };

  return (
    <React.Fragment>
      {isLoading && (
        <DxcSpinner margin="medium" label="Loading..." mode="overlay" />
      )}
      <ReactMarkdown
        className="overviewInfo"
        source={md}
        transformImageUri={transformImageUri(apiResource)}
      />
    </React.Fragment>
  );
};

export default OverviewTab;
