import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { DxcButton } from "@dxc-technology/halstack-react";
import layers from "./images/layers.svg";
import notebooks from "./images/notebooks.svg";
import cloudCheck from "./images/cloud-check.svg";
import cog from "./images/cog.svg";

import { typeface } from "../common/variables.js";
import { useScreenType } from "../common/utils";

const Build = () => {
  const history = useHistory();

  const onClick = () => {
    history.push("/apis/catalog");
  };

  const screenType = useScreenType();

  return (
    <StyledBuild screenType={screenType}>
      <BuildDescription screenType={screenType}>
        <BuildDescriptionContent screenType={screenType}>
          <BuildDescriptionTitle screenType={screenType}>
            APIs
          </BuildDescriptionTitle>
          <BuildDescriptionText screenType={screenType}>
            Bring your creativity and passion with you and start creating new
            products. Like you, we want to leave a footprint in the digital era.
            We are starting by making DXC Insurance APIs and data available.
          </BuildDescriptionText>
          {screenType !== "mobile" && (
            <DxcButton
              label="See the catalog"
              onClick={onClick}
              size="fitContent"
            />
          )}
        </BuildDescriptionContent>
      </BuildDescription>
      <BuildItems screenType={screenType}>
        <BuildItemsContent screenType={screenType}>
          <BuildItem screenType={screenType}>
            <Link to="/apis">
              <BuildItemIconContainer screenType={screenType}>
                <BuildItemIcon
                  src={layers}
                  alt="Workplace and Mobility Icon"
                  screenType={screenType}
                />
              </BuildItemIconContainer>
              <BuildItemText screenType={screenType}>API Guidelines</BuildItemText>
            </Link>
          </BuildItem>
          <BuildItem screenType={screenType}>
            <Link to="/apis/catalog">
              <BuildItemIconContainer screenType={screenType}>
                <BuildItemIcon
                  src={notebooks}
                  alt="Reference Apps"
                  screenType={screenType}
                />
              </BuildItemIconContainer>
              <BuildItemText screenType={screenType}>
                API Catalog
              </BuildItemText>
            </Link>
          </BuildItem>
          <BuildItem screenType={screenType}>
            <Link to="/apis/status">
              <BuildItemIconContainer screenType={screenType}>
                <BuildItemIcon
                  src={cloudCheck}
                  alt="API Status"
                  screenType={screenType}
                />
              </BuildItemIconContainer>
              <BuildItemText screenType={screenType}>API Status</BuildItemText>
            </Link>
          </BuildItem>
          <BuildItem screenType={screenType}>
            <Link to="/apis/tools">
              <BuildItemIconContainer screenType={screenType}>
                <BuildItemIcon
                  src={cog}
                  alt="API Tools"
                  screenType={screenType}
                />
              </BuildItemIconContainer>
              <BuildItemText screenType={screenType}>API Tools</BuildItemText>
            </Link>
          </BuildItem>
        </BuildItemsContent>
      </BuildItems>
      {screenType === "mobile" && (
        <DxcButton
          label="Start Creating Products"
          onClick={onClick}
          size="fillParent"
          margin="medium"
        />
      )}
    </StyledBuild>
  );
};

const StyledBuild = styled.div`
  display: flex;
  width: ${(props) => (props.screenType !== "mobile" ? "100%" : "auto")};
  flex-direction: ${(props) =>
    props.screenType === "desktop" ? "row" : "column"};
  padding: ${(props) => (props.screenType === "desktop" ? "84px 0" : "0")};
  margin-top: ${(props) => (props.screenType !== "desktop" ? "60px" : "")};
  height: ${(props) => (props.screenType === "desktop" ? "763px" : "auto")};
  background-color: ${(props) =>
    props.screenType !== "desktop" ? "#D9D9D9" : ""};
  ${(props) =>
    props.screenType === "mobile" ? "padding: 80px 40px 100px 40px" : ""};
  ${(props) =>
    props.screenType === "tablet"
      ? "padding: 80px 80px 100px 80px;  box-sizing: border-box;"
      : ""};
`;

const BuildDescription = styled.div`
  display: flex;
  width: ${(props) => (props.screenType === "desktop" ? "45%" : "100%")};
  background: ${(props) =>
    props.screenType === "desktop" ? "white" : "transparent"};
  padding-left: ${(props) => (props.screenType === "desktop" ? "10%" : "")};
  padding-top: ${(props) => (props.screenType === "desktop" ? "221px" : "")};
`;

const BuildDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  color: black;
  align-items: flex-start;
`;

const BuildDescriptionTitle = styled.div`
  font-size: ${(props) => (props.screenType !== "mobile" ? "48px" : "34px")};
  color: ${(props) => (props.screenType === "mobile" ? "#000" : "")};
  font-weight: ${(props) => (props.screenType === "mobile" ? "300" : "")};
  margin-bottom: 40px;
`;

const BuildDescriptionText = styled.div`
  margin-bottom: 60px;
  font-size: ${typeface.body.fontSize};
  letter-spacing: ${typeface.body.letterSpacing};
  text-transform: ${typeface.body.textTransform};
  width: ${(props) => (props.screenType === "mobile" ? "100%" : "93%")};
  font-weight: ${(props) => (props.screenType === "mobile" ? "400" : "")};
`;

const BuildItems = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "55%" : "100%")};
  background: ${(props) =>
    props.screenType === "desktop" ? "#f2f2f2" : "transparent"};
  display: flex;
  padding-top: ${(props) => (props.screenType !== "mobile" ? "140px" : "")};
  border-top-left-radius: 355px;
  border-bottom-left-radius: 355px;
`;

const BuildItemsContent = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "desktop" ? "column" : ""};
  flex-wrap: ${(props) => (props.screenType !== "desktop" ? "wrap" : "")};
  width: 100%;
  margin-left: ${(props) => (props.screenType === "desktop" ? "35%" : "")};
`;

const BuildItem = styled.div`
  width: ${(props) => (props.screenType !== "desktop" ? "50%" : "")};
  margin-bottom: 67px;
  & a {
    display: flex;
    flex-direction: ${(props) =>
      props.screenType === "desktop" ? "row" : "column"};
    align-items: center;
    text-decoration: none;
  }
`;

const BuildItemIconContainer = styled.div`
  height: 72px;
  width: 72px;
  background: black;
  margin-right: ${(props) => (props.screenType === "desktop" ? "40px" : "")};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
`;

const BuildItemIcon = styled.img`
  width: ${(props) => (props.screenType !== "desktop" ? "32px" : "")};
`;

const BuildItemText = styled.div`
  color: black;
  font-size: ${(props) =>
    props.screenType === "desktop"
      ? "24px"
      : props.screenType === "tablet"
      ? "18px"
      : typeface.altSubtitle.fontSize};
  letter-spacing: ${(props) =>
    props.screenType !== "mobile" ? "0" : typeface.altSubtitle.letterSpacing};
  margin-top: ${(props) => (props.screenType !== "desktop" ? "12px" : "")};
  text-align: ${(props) => (props.screenType !== "desktop" ? "center" : "")};
`;

export default Build;
