import React from "react";
import styled from "styled-components";
import {
  findIndustry,
  findDomain,
  getApisArray,
} from "./../../services/api-catalog";
import ApiCard from "./ApiCard";
import { useScreenType } from "../../common/utils";

const RelatedApisTab = ({ catalog, industry, domain, api, select }) => {
  const industryResource = findIndustry(catalog, industry);
  const domainResource = findDomain(catalog, industry, domain);

  const relatedApis = getApisArray(catalog, industry, domain);
  const screenType = useScreenType();

  return (
    <React.Fragment>
      {relatedApis && relatedApis.length !== 1 ? (
        <RelatedApisContainer>
          <RelatedApisInfo>
            Other {api.title} APIs that can be useful to you and your developing
            work.
          </RelatedApisInfo>
          <RelatedApisTag>
            <span>Other {api.title} APIs</span>
          </RelatedApisTag>
          <RelatedApis screenType={screenType}>
            {relatedApis.map((item) => {
              return item.key !== api.key ? (
                <ApiCard
                  industry={industryResource}
                  domain={domainResource}
                  api={item}
                  select={select}
                  showDescription={false}
                />
              ) : (
                ""
              );
            })}
          </RelatedApis>
        </RelatedApisContainer>
      ) : (
        <NoRelatedApisMessage screenType={screenType}>
          This API doesn't have related APIs
        </NoRelatedApisMessage>
      )}
    </React.Fragment>
  );
};

const RelatedApisContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RelatedApisInfo = styled.div`
  padding-bottom: 38px;
`;

const RelatedApisTag = styled.div`
  display: flex;
  padding: 8px 20px;
  background-color: #d9d9d9;
  margin-bottom: 30px;

  span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.88px;
  }
`;

const RelatedApis = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div > div > div {
    margin-left: ${(props) => (props.screenType !== "mobile" ? "12px" : "")};
    margin-bottom: 24px;
    height: 96px;
  }
`;

const NoRelatedApisMessage = styled.div`
  background: #f2f2f2;
  text-transform: uppercase;
  font-size: ${(props) => (props.screenType !== "mobile" ? "25px" : "16px")};
  text-align: center;
  padding: ${(props) => (props.screenType !== "mobile" ? "100px" : "80px")};
  margin-top: 60px;
  font-weight: bold;
  color: #00000054;
`;

export default RelatedApisTab;
