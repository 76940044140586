import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DxcInset } from "@dxc-technology/halstack-react";
import {
  getApiCatalog,
  getIndustriesArray,
} from "./../../services/api-catalog";
import SideNav from "../../components/apis/SideNav";
import ApiCatalogContext from "../../ApiCatalogContext";

const Sidenav = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [catalog, setCatalog] = useState(null);
  const { searchString } = useContext(ApiCatalogContext);
  const { selectedRegion } = useContext(ApiCatalogContext);

  useEffect(() => {
    getApiCatalog()
      .then((catalog) => {
        setCatalog(catalog);
      })
      .catch((err) => {
        setCatalog(null);
      });
  }, []);

  const params = new URLSearchParams(search);
  const selectedIndustry = params.get("industry");
  const selectedDomain = params.get("domain");

  const select = useCallback(
    ({ industry, domain, api }) => {
      history.push(
        (industry &&
          domain &&
          api &&
          `/apis/catalog/${industry}/${domain}/${api}?domain=${domain}&industry=${industry}`) ||
          (industry &&
            domain &&
            !api &&
            `/apis/catalog?domain=${domain}&industry=${industry}`) ||
          (industry &&
            !domain &&
            !api &&
            `/apis/catalog?industry=${industry}`) ||
          "/apis/catalog"
      );
    },
    [history]
  );

  return (
    <DxcInset space="1rem">
      <SideNav
        industries={getIndustriesArray(catalog)}
        selectedIndustry={selectedIndustry}
        selectedDomain={selectedDomain}
        searchString={searchString}
        selectedRegion={selectedRegion}
        select={select}
      />
    </DxcInset>
  );
};

export default Sidenav;
