import React from "react";
import styled from "styled-components";
import { useScreenType } from "../../common/utils";
import {
  DxcQuickNav,
  DxcButton,
  DxcLink,
  DxcInset,
  DxcFlex,
} from "@dxc-technology/halstack-react";

const GuidelinesApi = () => {
  const screenType = useScreenType();

  const links = [
    {
      label: "Understanding Hypermedia API",
    },
    {
      label: "Consuming Hypermedia API",
    },
    {
      label: "Navigating hypermedia-based APIs",
    },
  ];

  const onClickHateoas = () => {
    window.location.href =
      "https://blogs.dxc.technology/2016/09/13/rest-in-our-not-so-humble-opinion/";
  };

  const onClickHal = () => {
    window.location.href = "http://stateless.co/hal_specification.html";
  };

  return (
    <React.Fragment>
      {screenType !== "desktop" && <Strip></Strip>}
      <GuidelinesContainer screenType={screenType}>
        <ContentTitle>Your Coding Reference to DXC APIs.</ContentTitle>
        <Introduction>
          <SectionTitle>Introduction</SectionTitle>

          <Paragraph>
            DXC REST APIs are great for turning your ideas into real apps. You
            can validate your concepts or create entire new business solutions
            by consuming a very complete set of development data that we have
            made available to you. DXC Developer Central supports two types of
            APIs.
          </Paragraph>
          <List>
            <li>
              <strong>Spec-based APIs</strong>: These are APIs that are defined
              following OpenAPI's Swagger spec files. Their implementation can
              be at any level in Richardson Maturity Level scale. You will be
              able to use and test these APIs through a SwaggerUI component
              embedded in the developer portal.
            </li>
            <br />
            <li>
              <strong>Hypermedia-based APIs</strong>: These are
              auto-discoverable REST APIs following Richardson Maturity Level 3.
              They fully leverage the HATEOAS pattern and as such, they are
              totally dynamic and implementation-driven. There is no OpenAPI
              Swagger spec defining these APIs and you will be able to browse
              them through a new DXC component called HAL UI, also embedded in
              the developer portal.
            </li>
          </List>
          <Paragraph>
            DXC actively promotes and supports the hypermedia-based pattern for
            REST APIs. Thus, the vast majority of APIs available in DXC
            Developer Central are auto-discoverable and hypermedia-based. In the
            sections below you can find more documentation and resources on
            understanding, navigating and consuming this type of REST APIs.
          </Paragraph>
        </Introduction>

        <DxcInset>
          <DxcFlex direction="row">
            <DxcFlex direction="column" gap="2rem">
              <DxcFlex direction="column">
                <SubtitleContainer id="understanding-hypermedia-api">
                  <SubtitleContent>
                    Understanding Hypermedia API
                  </SubtitleContent>
                </SubtitleContainer>

                <SectionTitle>HateOAS</SectionTitle>
                <Paragraph>
                  Resources of the API expose URI links to other resources
                  within the application, both other “entity” resources and
                  “action” resources. Resource representations that include such
                  data are known as hypermedia representations. This is the
                  start of using HTTP as the state machine for your application
                  - what the original thesis describing REST calls Hypermedia as
                  the engine of application state - HATEOAS - which basically
                  means that by following the URIs contained i n your current
                  resource (or your context resource), you can make all the
                  legal state transitions from your current state. By
                  implication, the context resource should not advertise URIs
                  for illegal transitions from its current state - e.g. a
                  non-deletable resource should not advertise a DELETE link
                  (although see below for more on the difference between the
                  semantics of the API and how the server implements those
                  semantics).
                </Paragraph>
                <DxcButton
                  label="I want to know more"
                  margin="medium"
                  onClick={onClickHateoas}
                ></DxcButton>
                <SectionTitle>Resource representation using HAL</SectionTitle>
                <Paragraph>The recommendation, then, is</Paragraph>
                <List>
                  <li>
                    Use the HAL structure to present application state
                    information, for a number of reasons:
                    <List>
                      <li style={{ marginBottom: "8px" }}>
                        It has a good base of support as an IETF draft
                        recommendation
                      </li>
                      <li style={{ marginBottom: "8px" }}>
                        It presents an intuitive structure that a “slightly less
                        generic” client can easily traverse
                      </li>
                      <li style={{ marginBottom: "8px" }}>
                        It supports both JSON and XML (practically all the
                        others only describe for JSON)
                      </li>
                      <li style={{ marginBottom: "8px" }}>
                        It has registered MIME Types (the Content-Type and
                        Accept) header values used in content negotiation) for
                        both JSON and XML
                      </li>
                      <li style={{ marginBottom: "8px" }}>
                        By exposing links in the representation itself, those
                        links can be dynamically tailored by the server based
                        upon requester context (e.g. security credentials). This
                        is harder to achieve when links are exposed only via a
                        schema.
                      </li>
                      <li style={{ marginBottom: "8px" }}>
                        By not exposing interaction information (i.e. resource
                        state change information) in it’s link structures, it
                        implements the “purest” approach to the principle that a
                        resource should only be responsible for managing its own
                        API - specifically, it is not a given resource’s
                        responsibility to expose details of the APIs of the
                        resources to which it is linked (see the section on
                        Resources).
                      </li>
                      <li>
                        The HAL format includes a “hypertext cache pattern”
                        mechanism that can be exploited to lessen the chattiness
                        of a RESTful API
                      </li>
                    </List>
                  </li>
                  <br />
                  <li>
                    All implementations MUST support the type
                    application/vnd.hal+json, and that should be the default
                    representation (i.e. used when no other requested
                    representation can be supported, rather than returning an
                    HTTP 406 Not Acceptable error). It should also be the
                    representation returned for the generic JSON media type
                    application/json.
                  </li>
                  <br />
                  <li>
                    In addition, if the implementation supports XML resource
                    representations, then it MUST support the type
                    application/vnd.hal+xml and this should be the
                    representation returned for the generic XML media types
                    text/xml and application/xml.
                  </li>
                  <br />
                  <li>
                    Resources representations SHOULD be accompanied by a link to
                    a “type resource” (i.e. schema) that describes the business
                    structure of that representation.
                  </li>
                  <br />
                  <li>
                    For XML, obviously this should be a standard XML schema.
                  </li>
                  <br />
                  <li>
                    For JSON, it should be{" "}
                    <DxcLink href="http://json-schema.org/draft/2019-09/json-schema-hypermedia.html">
                      JSON Hyper Schema
                    </DxcLink>
                    .
                  </li>
                </List>
                <DxcButton
                  label="I want to know more"
                  margin="medium"
                  onClick={onClickHal}
                ></DxcButton>
                <SectionTitle>Resources</SectionTitle>
                <Paragraph>
                  In REST each resource can be considered to have its own API-
                  for example it is no longer a case of “the insurance API” - it
                  is a case of “the policies collection API”, then the “policy
                  API”, then the linked “coverages collection API”, then the
                  “coverage API” etc. etc. It is helpful, at least at this
                  level, to treat each resource in a similar fashion to an
                  object (in fact, Roy Fielding originally referred to REST as
                  the HTTP Object Model):
                </Paragraph>
                <List>
                  <li>
                    A resource encapsulates a single concept to which we want to
                    provide an API
                  </li>
                  <li>
                    A resource has state, part of which comprises links to other
                    resources - c.f. a n OO instance containing references to
                    other instances
                  </li>
                  <li>
                    A resource’s API is “discoverable” - c.f. introspection, or
                    reflection, in OO languages
                  </li>
                  <li>
                    A resource is responsible solely for exposing and handing
                    its own state and API
                  </li>
                </List>
                <Paragraph>
                  Applying these concepts to resource API design ensures that
                  the overall application API is as flexible, loosely coupled,
                  and dynamic as possible.
                </Paragraph>
                <Paragraph>
                  While not covered here, the concept of bounded contexts, and
                  the discipline of Domain Driven Design both serve to reinforce
                  this view of a resource-centered API and should be strong
                  candidates for system design approaches when building an
                  application that is intended to be manipulated by a RESTful
                  API. The following sections describe in more detail design and
                  usage considerations pertaining to resources.
                </Paragraph>
                <SectionTitle>Resource Structure</SectionTitle>
                <Paragraph>
                  Note that in the following text, the terms parent resource and
                  child resource are avoided. This is because we are attempting
                  to describe an approach to resource model and API design that
                  does not expose a hierarchical view of a resource model to the
                  client.
                </Paragraph>
                <Paragraph>
                  By “A hierarchical model exposed to the client” we mean that
                  the client is aware of and expected to be affected by the
                  hierarchical semantics of a relationship between resources
                  implied by the resource paths. For example, a server may
                  expose two resources:
                </Paragraph>
                <Paragraph>
                  <Code>/quotes/quote-1</Code> and{" "}
                  <Code>/quotes/quote-1/risks/risk-1</Code>
                </Paragraph>
                <Paragraph>
                  There is nothing wrong with this implied hierarchy from the
                  server’s perspective - it clearly allows the quotes collection
                  to have individual “child” quote resources, each of which has
                  a risks “child” collection resource which, in turn, contain
                  their own “child” risk resources.
                </Paragraph>
                <Paragraph>
                  Using such a URI, the server can easily find the server-side
                  elements that make up a specific risk covered by a specific
                  quote. It can also easily delete all the “sub-resources” of a
                  quote when a quote is deleted.
                </Paragraph>
                <Paragraph>
                  However, requiring the client to be aware of this hierarchy -
                  for example, by forcing the client to construct the path to a
                  risk from a quote - immediately ties the client to the
                  internal server-side context associated with the quote and
                  risk resources - meaning that the server has no freedom to
                  change its internal semantics if, in the future this is deemed
                  necessary.
                </Paragraph>
                <Paragraph>
                  Consequently, “exposing” implicit relationships based upon
                  path hierarchy to the client is strongly discouraged. Instead
                  clients should use hypermedia links “blindly” to traverse from
                  one resource to its related resources - with no requirement to
                  “understand” the URI path structure.
                </Paragraph>
                <Paragraph>
                  Indeed, the recommendation is for the path segments of the
                  resource URI below the “well known entry point” segments of
                  that path be obfuscated by some sort of reversible encoding.
                  This actively prevents the client from manipulating the
                  resource URIs and allows the server to place anything in the
                  URI path that it needs in order to find the server-side
                  elements related to the addressed resource, including
                  contextual hints related to the reference.
                </Paragraph>
                <SectionTitle style={{ marginTop: "40px" }}>
                  The OPTIONS Response Format
                </SectionTitle>
                <Paragraph>
                  As with hypermedia in a resource representation, the metadata
                  that describes how to interact with links published in such a
                  representation must also be presented in a standard format.
                </Paragraph>
                <Paragraph>
                  As mentioned before, this metadata is retrieved using the
                  OPTIONS HTTP method. The metadata must provide all the
                  information necessary for a client to build a correct request
                  to interact with the target resource of the link. It really is
                  not very important which metadata representation is chosen as
                  long as the chosen format meets this requirement.
                </Paragraph>
                <Paragraph>
                  In general, the OPTIONS response body is a fully valid JSON
                  Hyperschema document that describes
                </Paragraph>
                <List>
                  <li>
                    the current interaction options for the target resource,
                    using the JSON HyperSchema “links” array property, and,
                  </li>
                  <br />
                  <li>
                    optionally, the “eventually required” data properties that
                    must be populated for the resource to be considered valid,
                    or complete, by the backend service, using the JSON Schema
                    “properties” and “required” properties at the same level as
                    the “links” property. The concepts of business validity and
                    completeness being entirely defined by the the service and
                    the target resource.
                  </li>
                </List>
              </DxcFlex>
              <DxcFlex direction="column">
                <SubtitleContainer id="consuming-hypermedia-api">
                  <SubtitleContent>Consuming Hypermedia API</SubtitleContent>
                </SubtitleContainer>

                <SectionTitle>RESTful Use of Methods</SectionTitle>
                <Paragraph>
                  Generally the HTTP entity/body contained in the response to a
                  REST API request will be of one of two types:
                </Paragraph>
                <List>
                  <li>
                    A resource representation - i.e. a formatted view of the
                    state of the addressed resource
                  </li>
                  <br />
                  <li>
                    An outcome report - i.e. a report detailing the outcome
                    (success or failure) of the request
                  </li>
                </List>
                <Code>OPTIONS</Code>
                <Paragraph>
                  The OPTIONS request is a slightly special case because it
                  returns interaction information pertaining to the addressed
                  resource in its current state, but even that can be deemed as
                  a view of a different aspect of that state and thus can also
                  be classed as a resource representation.
                </Paragraph>
                <Paragraph>
                  It is also possible (but rare) that the response does not
                  contain an entity - in which case the entire outcome can be
                  derived from the status code and other metadata (i.e. headers)
                  accompanying the response.
                </Paragraph>
                <Code>GET</Code>
                <Paragraph>
                  GET expresses the client intent to retrieve a representation
                  of the resource identified by the request URI. The format of
                  the representation can be negotiated between client and
                  server. The server may offer facilities to tailor the content
                  of the returned representation via URI query parameters (e.g.
                  collection paging functionality)..
                </Paragraph>
                <Paragraph>
                  Note that GET requests must not include a request ENTITY! Such
                  use was expressly prohibited by the original HTTP 1.1
                  specification and is simply not supported by most browsers and
                  other HTTP client software. The HTTP/2 rewrite of the HTTP 1.1
                  semantics allows for a request entity on GET (and DELETE)
                  solely in order to remove impediments of implementing the
                  standard HTTP method rsing algorithm introduced by HTTP/2 -
                  the semantics of such a body are defined as “having no
                  meaning”!
                </Paragraph>
                <Paragraph>
                  You can do a GET to Collection or Document resources
                </Paragraph>
                <Code>PUT</Code>
                <Paragraph>
                  PUT expresses the client intent to store the data of the
                  request body as a resource on the server and assign to it the
                  request URI such that the resource is retrievable in the
                  future using the GET method on that same URI. If a resource
                  already exists the PUT expresses the intent to replace the
                  existing resource with the data in the request body.
                </Paragraph>
                <Paragraph>
                  In practice, PUT is used to add new resources to a “store”
                  resource, or to update the addressed resource. The semantics
                  of PUT require the entity passed in the request to be a “full
                  representation” of the addressed resource. That is, all
                  attributes must be present, modified or not.For that reason it
                  will be rarely used for update purposes, if at all.
                </Paragraph>
                <Code>POST</Code>
                <Paragraph>
                  PATCH expresses the client intent to update the resource
                  addressed by the URI in some way determined by the content and
                  syntax of the request body. So, the PATCH method is used to
                  perform a “delta update” (i.e. update some attributes) of the
                  referenced resource, using the contents of the passed HTTP
                  entity to control the update process.
                </Paragraph>
                <Paragraph>
                  While the natural tendency is to think of a PATCH request body
                  as simply a list of assignments to a subset of the properties
                  of the target resource, the PATCH specification itself does
                  not prescribe a specific format or semantic for that body, and
                  allows for far more powerful constructs to be used to convey
                  the client’s intention to the server.
                </Paragraph>
                <SectionTitle>Example Interactions</SectionTitle>
                <Paragraph>
                  Below are a number of sample interactions that are intended to
                  help clarify the use of HTTP methods, and the use of
                  collection, document and controller resources. Many of the
                  examples use these concepts with Operation resources to
                  further demonstrate the concept of modelling operations as
                  document resources. Where the URI would fold due to page width
                  restrictions, the first couple of segments of the URI have
                  been replaced with an ellipsis.
                </Paragraph>
                <Code>
                  GET /dxc/insurance/claims/claimEventFolder?customerId=56789
                </Code>
                <Paragraph>
                  returns the list of claim event folder URIs for the specified
                  customer ID
                </Paragraph>
                <Code>GET /dxc/insurance/claims/claimEventFolder/12345</Code>
                <Paragraph>
                  returns the value of all attributes of CEF 12345
                </Paragraph>
                <Code>
                  OPTIONS /dxc/insurance/claims/claimEventFolder/12345
                </Code>
                <Paragraph>
                  returns possible operations, inquiries, and other metadata
                  describing CEF 12345 - i.e. facilitates HATEOAS capabilities
                </Paragraph>
                <Code>
                  GET
                  .../claims/claimEventFolder/12345/operations/validate_cef/6767
                </Code>
                <Paragraph>
                  returns all the attributes of the 6767 document of the
                  operation type “validate_cef”
                </Paragraph>
                <Code>POST /dxc/insurance/claims/claimEventFolder</Code>
                <Paragraph>
                  with an appropriately formatted HTTP request entity containing
                  the necessary CEF creation attribute values, creates a CEF and
                  returns the new instance’s URI in the response Location header
                  and the Content-Location header, along with an appropriate
                  representation of the new CEF resource in the response body
                  and any other headers that would normally be returned for a
                  GET request for the new resource.
                </Paragraph>
                <Code>
                  POST .../claims/claimEventFolder/12345/operations/validate_cef
                </Code>
                <Paragraph>
                  with an appropriately formatted HTTP request entity containing
                  the necessary validate_cef operation parameter values, creates
                  a validate_cef operation resource for the CEF identified by
                  the URI /dxc/insurance/claims/claimEventFolder/12345, and
                  returns the new operation instance’s URI in the response
                  Location header and the Content-Location header, along with an
                  appropriate representation of the new operation resource in
                  the response body and any other headers that would normally be
                  returned for a GET request for the new resource.
                </Paragraph>
                <Code>
                  POST
                  .../claims/claimEventFolder/12345/operations/validate_cef/execute
                </Code>
                <Paragraph>
                  with an appropriately formatted HTTP request entity containing
                  the necessary validate_cef operation parameter values, creates
                  a validate_cef operation resource for the CEF identified by
                  the URI /dxc/insurance/claims/claimEventFolder/12345, and
                  attempts to execute it. If the creation, population and
                  execution are all successful, the new operation instance’s URI
                  is returned in the response Location header, otherwise the
                  operation is NOT persisted, and an appropriate error is
                  returned.
                </Paragraph>
                <Code>
                  PATCH
                  .../claims/claimEventFolder/12345/operations/validate_cef/9090
                </Code>
                <Paragraph>
                  with an appropriately formatted HTTP request entity, modifies
                  the modifiable attributes (i.e. arguments) of the (as-yet not
                  executed) operation instance, and returns a representation of
                  the updated resource in the response body, and the
                  Content-Location header set to the original request URI.
                </Paragraph>
                <Code>
                  OPTIONS
                  .../claims/claimEventFolder/12345/operations/validate_cef/9090
                </Code>
                <Paragraph>
                  discovers what you can do with the operation instance - e.g.
                  is it in a state where the DELETE method can be executed on
                  it, or which, if any, the operation’s controllers (e.g.
                  execute, undo, redo, …) can be executed.
                </Paragraph>
                <Code>
                  DELETE
                  .../claims/claimEventFolder/12345/operations/validate_cef/9090
                </Code>
                <Paragraph>
                  deletes the operation instance, if it is in a state where the
                  DELETE method can be executed on it (otherwise a 405 Method
                  Not Allowed error will be returned). Normally an operation
                  will not be DELETE-able after it has been executed, but maybe
                  DELETE-able before that (to facilitate abandoning operations
                  before the decision to execute has been confirmed).
                </Paragraph>
                <Code>
                  POST
                  .../claims/claimEventFolder/12345/operations/validate_cef/9090/execute
                </Code>
                <Paragraph>
                  (normally with an empty HTTP request entity) runs the
                  operation’s execute controller, if it is in a state where this
                  controller can be run (otherwise a 405 Method Not Allowed
                  error will be returned).
                </Paragraph>
                <Code>
                  GET
                  .../claims/claimEventFolder/12345/operations/validate_cef/9090
                </Code>
                <Paragraph>
                  returns the (content negotiated) representation of the current
                  state of the operation - so, for example, the arguments of the
                  operation, whether it has been executed, whether it can be
                  “undone” (although the OPTIONS method would be the more
                  RESTful way of determining that), execution outcome (e.g. log)
                  information etc.
                </Paragraph>
                <SectionTitle>Response HTTP Codes</SectionTitle>
                <List>
                  <li style={{ marginBottom: "8px" }}>
                    200 after a successful GET or PATCH
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    201 after a successful POST
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    400 after a bad request, for example a PATCH with a wrong
                    property name in the body
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    401 for unauthorized user
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    403 for a forbidden request : missing authentication token,
                    or invalid API key
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    404 after a request on a resource that does not exist
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    500 or 502 after an internal server error
                  </li>
                  <li style={{ marginBottom: "8px" }}>504 after a time-out</li>
                </List>
                <SectionTitle>Request Headers</SectionTitle>
                <List>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Accept</strong>: The Accept header should be set to
                    application/vnd.hal+json if the client would like to receive
                    the resource representation in the body of the response.
                    This is normal for a GET, and is often used for POST or
                    PATCH, in order to save a subsequent GET. In case the POST /
                    PATCH is successful, the API will return the representation.
                    In case it is not, the server will return an “outcome
                    report”, which is very similar to the “status report”.
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Content-Language</strong>: Each request to the API
                    has to be made in a given language, and the server will try
                    to respond in that language.
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>x-api-key</strong>: API key that identifies the
                    client doing the API call. This is needed for security
                    reasons.
                  </li>
                </List>
                <SectionTitle>Response Headers</SectionTitle>
                <List>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Content-Location</strong>: After a successful POST
                    or PATCH, the API will return a HTTP code (e.g. 200) as well
                    as an updated representation of the resource. This avoids
                    sending a GET after each POST or PATCH. The location of the
                    created resource can be found in the Content-Location header
                    (and of course, it is also in the body that contains the
                    representation, for example in the link “self”). If the
                    Content-Location header is not returned by the API, it means
                    that the body actually contains an “outcome report”, and not
                    a resource representation
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Content-Language</strong>: Language that the server
                    has used to provide the representation
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Content-Type</strong>: Format of the representation.
                    Usually JSON + HAL but could be plain JSON, PDF, ...
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>Link</strong>: Provides the URI of resources that
                    contain metadata about the current resource. For DXC APIs,
                    this is the resource schema. All these links can also be
                    found in the response body (if it contains a representation)
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    <strong>x-dxc-modified</strong>: This header returns a list
                    of URIs of resources that have been impacted after a PATCH
                    (i.e. Side-effect Notification Pattern). For example a PATCH
                    on a Quote Coverage’s duration could impact the Quote’s
                    premium. This is to help API consumer programs (especially
                    visual UIs) to know which resources need to be refreshed.
                  </li>
                </List>
              </DxcFlex>
              <DxcFlex direction="column">
                <SubtitleContainer id="navigating-hypermedia-based-apis">
                  <SubtitleContent>
                    Navigating hypermedia-based APIs
                  </SubtitleContent>
                </SubtitleContainer>
                <Paragraph>
                  Developers can browse auto-discoverable APIs in DXC Developer
                  Central through HAL UI, a new user interface that has been
                  developed by DXC for this specific type of APIs. HAL UI
                  provides a visual mechanism to crawl and discover resources
                  and operations dynamically for those APIs meeting the
                  following criteria:
                </Paragraph>
                <List>
                  <li>
                    They implement the HATEOAS pattern for hypermedia-driven
                    resource navigation
                  </li>
                  <li>
                    They expose their available links and properties through
                    HTTP OPTIONS
                  </li>
                  <li>
                    They represent their resources using HAL specification
                  </li>
                </List>
                <Paragraph>
                  In contrary to other path-based approaches such as OpenAPI's
                  Swagger UI (where static API specs are used to describe the
                  list of pre-defined path to resources and available
                  operations) HAL UI allows both API consumers and producers to
                  work with self-documented REST APIs. Thus, everytime an API
                  implementation changes, these updates will be automatically
                  available through HAL UI without the need of importing or
                  configuring any API spec.
                </Paragraph>
                <SectionTitle>API Entry Point</SectionTitle>
                <Paragraph>
                  APIs are just user interfaces, it just happens that the user
                  is another machine. Like in the visual world, those interfaces
                  need to be friendly, which in the API world means they need to
                  be easyly discoverable by the consumer program. Following this
                  principle, auto-discoverable APIs based on hypermedia are
                  typically exposed through a root collection resource as the
                  main entry point. For example, in an insurance context, this
                  could be a QUOTES collection, a CONTRACTS collection or a
                  CLAIMS collection.
                </Paragraph>
                <Paragraph>
                  The principle on which HAL UI is based is very simple. Every
                  interaction during the API navigation is fully based on HTTP
                  OPTIONS, displaying the information available to that resource
                  at that particular moment in time. In other words, API
                  documentation is dynamic and driven by its implementation.
                  This OPTIONS response content is represented using JSON
                  Hyperschema specification.
                </Paragraph>
                <List>
                  <li>
                    <strong>Operations</strong>: List of operations/interactions
                    available for that resource as described in resource's links
                    attribute of the HTTP OPTIONS response. In a REST
                    representation, these are typically HTTP verbs, described
                    through a "rel" attribute.
                  </li>
                  <li>
                    <strong>Properties</strong>: List of properties available
                    for that resource as described in resource's properties
                    attribute of the HTTP OPTIONS response.
                  </li>
                </List>
                <Paragraph>
                  <strong>Remember</strong>: the default visual display of a
                  resource after a navigation in HAL UI is not the result of a
                  GET call, but the result of an OPTIONS call. In order to
                  actually execute the available HTTP operations or retrieve the
                  actual values of the properties for that resource, the user
                  will have to select an operation and try it out.
                </Paragraph>
                <SectionTitle>Resource URI Structure</SectionTitle>
                <Paragraph>
                  <strong>Lesson number 1</strong>: in contrary to path-based
                  APIs, and not surprisingly, there is no URI structure in
                  hypermedia-based APIs. This is due to the fact that resource
                  URIs are dynamically generated and auto-discovered by the
                  consumer program through HTTP OPTIONS calls, so there is no
                  need to have a pre-fixated URI structure. In hypermedia-based
                  APIs, URIs could even be offuscated or encrypted, improving
                  overall security of our implementation.
                </Paragraph>
                <SectionTitle>Getting a Resource in HAL UI</SectionTitle>
                <Paragraph>
                  <strong>Properties</strong>: Fields in this section are
                  obtained from the OPTIONS call and are structured and
                  displayed as follows:
                </Paragraph>
                <List>
                  <li>
                    <strong>For collection resources</strong>: These are input
                    fields used as filtering criteria during the execution of
                    the GET call. If no values are entered, the GET call will
                    return all elements in the collection.
                  </li>
                  <li>
                    <strong>For document resources</strong>: This is typically
                    empty for this type of resources (as no properties are
                    needed to GET a document resource, typically)
                  </li>
                </List>
                <Paragraph>
                  <strong>Response</strong>: Data in section is obtained from
                  the actual GET response and is structured and displayed as
                  follows:
                </Paragraph>
                <List>
                  <li>
                    <strong>Links</strong>: These are navigable hypermedia links
                    to other resources within the current resource network as
                    per the _links attribute in the response body.
                  </li>
                  <li>
                    <strong>Items</strong>: If the resource is a collection,
                    these are navigable hypermedia links to the different
                    elements of the collection as per the _links.item array
                    attribute in the response body. In order to help with the
                    navigation, the user can preview the properties of every
                    resource within the collection by hovering any item in the
                    list.
                  </li>
                  <li>
                    <strong>Headers and Body</strong>: HAL UI also displays
                    resource's raw data of the response so the user can inspect
                    the properties manually.
                  </li>
                </List>
                <SectionTitle>Patching a Resource in HAL UI</SectionTitle>
                <Paragraph>
                  <strong>Properties</strong>: Fields in this section are
                  obtained from the OPTIONS call. Every field represent a
                  PATCHable property of the resource, so when the user executes
                  the operation, the resource will be updated with the values
                  entered in each field. Please note there could be mandatory
                  attributes for PATCHing, marked with asterisks.
                </Paragraph>
                <Paragraph>
                  <strong>Response</strong>: Data in this section is obtained
                  from the actual PATCH response and is structured and displayed
                  as follows:
                </Paragraph>
                <List>
                  <li>
                    <strong>Links</strong>: These are navigable hypermedia links
                    to other resources within the current resource network as
                    per the updated _links attribute in the response body.
                  </li>
                  <li>
                    <strong>Items</strong>: Typically empty, as collection
                    resources are not PATCHable and document resources do not
                    have _links.item property.
                  </li>
                  <li>
                    <strong>Headers and Body</strong>: HAL UI also displays
                    updated resource's raw data of the response so the user can
                    inspect the properties manually.
                  </li>
                </List>
                <Paragraph>
                  The same principles are applied to the PUT operation.
                </Paragraph>
                <SectionTitle>Posting a Resource in HAL UI</SectionTitle>
                <Paragraph>
                  <strong>Properties</strong>: Fields in this section are
                  obtained from the OPTIONS call. Every field represent a
                  property of the resource we are creating through the POST
                  call, so when the user executes the operation, the resource
                  will be created with the values entered in each field. Please
                  note there could be mandatory attributes for POSTing, marked
                  with asterisks.
                </Paragraph>
                <Paragraph>
                  <strong>Response</strong>: Data in this section is obtained
                  from the actual POST response. This is typically applied to
                  collection resources only. The response contains a
                  representation of the document resource just created, which
                  has been added to the collection. Response data is structured
                  and displayed as follows:
                </Paragraph>
                <List>
                  <li>
                    <strong>Links</strong>: These are navigable hypermedia links
                    to other resources within the current resource network as
                    per the created resource's <Code>_links</Code> attribute in
                    the response body.
                  </li>
                  <li>
                    <strong>Items</strong>: Typically empty, as the response
                    data from the POST is not the collection resource, but the
                    created document resource. As we already know, document
                    resources do not have <Code>_links.item</Code> property.
                  </li>
                  <li>
                    <strong>Headers and Body</strong>: HAL UI also displays
                    created resource's raw data of the response so the user can
                    inspect the properties manually.
                  </li>
                </List>
              </DxcFlex>
            </DxcFlex>
            <QuickNavContainer screenType={screenType}>
              <DxcQuickNav links={links}></DxcQuickNav>
            </QuickNavContainer>
          </DxcFlex>
        </DxcInset>
      </GuidelinesContainer>
    </React.Fragment>
  );
};

const GuidelinesContainer = styled.div`
  & * {
    font-family: "Open Sans";
  }
  margin: ${(props) =>
    props.screenType === "desktop"
      ? "110px 150px"
      : props.screenType === "tablet"
      ? "0 80px"
      : "0 40px"};
`;

const ContentTitle = styled.h4`
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-weight: 300;
`;

const SubtitleContent = styled.h3`
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-weight: 400;
  margin-top: 64px;
`;

const SectionTitle = styled.h3`
  color: #000000de;
  letter-spacing: 0.24px;
  font-size: 20px;
  font-weight: 300;
`;

const Introduction = styled.div`
  padding-bottom: 50px;

  & + div {
    div.MuiTabs-flexContainer > button {
      max-width: 200px;
      line-height: 18px;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #666666;
  letter-spacing: 0.49px;
  padding: 0px 2%;
  word-break: break-word;
`;

const List = styled.ul`
  padding: 16px 6%;
  li {
    color: #666;
    line-height: 22px;
  }
`;

const Code = styled.code`
  width: fit-content;
  background-color: #f2f2f2;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Courier New !important;
  word-break: break-all;
`;

const Strip = styled.div`
  width: 100%;
  background-color: black;
  height: 40px;

  & + div > div:last-of-type {
    overflow: auto;
  }
`;

const QuickNavContainer = styled.div`
  position: sticky;
  margin-top: 64px;
  margin-left: 24px;
  max-width: 300px;
  top: calc(64px + 24px);
  max-height: calc(100vh - 64px);
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #66666626;
    border-radius: 3px;
  }

  ${(props) => props.screenType !== "desktop" && "display: none;"};
`;

const SubtitleContainer = styled.div`
  scroll-margin-top: 64px;
`;

export default GuidelinesApi;
