import React from "react";
import styled from "styled-components";
import { DxcSpinner } from "@dxc-technology/halstack-react";
import { useScreenType } from "../../common/utils";

const ApiStatus = (props) => {
  const screenType = useScreenType();

  return (
    <APIStatusContainer status={props.status}>
      <Status status={props.status}>
        {props.status === "loading" ? (
          <DxcSpinner mode="small" />
        ) : (
          props.status
        )}
      </Status>
      <Name screenType={screenType}>{props.title}</Name>
    </APIStatusContainer>
  );
};

const APIStatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f9f9f9;
`;

const Status = styled.div`
  width: 110px;
  min-width: 110px;
  height: 46px;
  background: ${(props) =>
    props.status
      ? props.status === "OK"
        ? "#CBF89C"
        : props.status === "NOH"
        ? "#E5E5E5"
        : props.status === "KO"
        ? "#F99393"
        : "#FFF352"
      : "#FCFCFC"};
  font: bold 14px/19px Open Sans;
  letter-spacing: 0.24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.div`
  margin-left: ${(props) => (props.screenType ? "40px" : "20px")};
`;

export default ApiStatus;
