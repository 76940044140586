import axios from "axios";

export const isHal = (api) => api && api.definitions && api.definitions.hateoas;

export const formURLFromAPISpec = (api) =>
  `${api.schemes}://${api.host}${api.basePath}`;

export const getHeadersFromAPISpec = (api) =>
  (api && api.definitions && api.definitions.headers) || {};

export const requiresAuthentication = (api) =>
  (api && api.definitions && api.definitions.integratorSecurity) || false;

export const isWellFormed = (apiResponse) => apiResponse && apiResponse.links;

export const getStatus = async (spec, url, apiKey) => {
  let headers;
  if (isHal(spec)) {
    if (requiresAuthentication(spec)) {
      headers = Object.assign({}, getHeadersFromAPISpec(spec), {
        "x-api-key": apiKey,
      });
      return callAPI(url, headers);
    } else {
      headers = Object.assign({}, getHeadersFromAPISpec(spec), {
        "x-api-key": apiKey,
      });
      return callAPI(url, headers);
    }
  } else {
    return "NOH";
  }
};

const callAPI = (url, headers) =>
  axios({
    method: "OPTIONS",
    headers: headers,
    url: url,
  })
    .then((response) => (isWellFormed(response.data) ? "OK" : "NOK"))
    .catch((error) => "KO");
