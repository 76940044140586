import React, { useRef } from "react";
import styled from "styled-components";

import { typeface } from "../../common/variables.js";
import { DxcCard } from "@dxc-technology/halstack-react";

import devLogo from "./images/devIcon.svg";
import reactLogo from "../../common/images/react-brands-color.svg";
import uxPinLogo from "./images/Uxpin.png";
import rCDKIcon from "./images/react_cdk_icon.png";
import rHALIcon from "./images/react_hal_icon.png";

import { useScreenType } from "../../common/utils";

const Tools = () => {
  const ref = useRef(null);
  const screenType = useScreenType();

  const handleClick = () => {
    if (ref && ref.current) {
      window.scroll({
        top: ref.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const tools = [
    {
      version: "Version 23.1.32",
      logo: uxPinLogo,
      name: "Tools for designers",
      description:
        "At present, We are using UXPin as Design and Prototype tool. With Halstack-UXPin merge, designers can create fast prototypes by dragging and dropping the Halstack-CDK components. Halstack-UXPin allows you to design with React components to achieve full consistency with the final product",
      color: "#FFD9F2",
      linkText: "Watch the video",
      hasClick: false,
      linkHref: "/Halstack Prototyping Library - Getting Started.mp4"
    },
    {
      version: "Version 16.12.0",
      logo: devLogo,
      name: "Tools for developers",
      description:
        "Develop your applications following the Design Guidelines with this set of tools. They encapsulate the visual logic of the Design Guidelines, speeding up the development process and guaranteeing consistency across DXC applications.",
      linkText: "See more about dev tools",
      color: "#61DAFB",
      linkHref: { handleClick },
      hasClick: true,
    },
  ];

  return (
    <React.Fragment>
      {screenType !== "desktop" && <ToolBar></ToolBar>}
      <ToolsContainer screenType={screenType}>
        <ToolsDescription screenType={screenType}>
          <ToolsTitle screenType={screenType}>
            Halstack Design System Tools
          </ToolsTitle>
          <ToolsText screenType={screenType}>
            <p>
              In addition to our Design guidelines, DXC provides a set of tools
              for designers and developers that enforces those principles
              out-of-the-box.
            </p>
            <p>
              Better visual consistency and faster application development cyles
              are only some of the benefits of using our tool set. Designers and
              developers will be able to come up with new User Experiences that
              already speak Hasltack Design System's visual language, resulting
              in simpler and more easy-to-use applications for our final users.
            </p>
          </ToolsText>
        </ToolsDescription>
        <ToolsList className="list" screenType={screenType}>
          {tools.map((tool) => {
            return (
              <ToolContainer
                className="container"
                screenType={screenType}
                key={tool.name}
              >
                <ToolWrapper screenType={screenType}>
                  {/* <ToolVersion>{tool.version}</ToolVersion> */}
                  <ToolLogoNameContainer screenType={screenType}>
                    <ToolLogo
                      src={tool.logo}
                      screenType={screenType}
                    ></ToolLogo>
                    <ToolName screenType={screenType}>{tool.name}</ToolName>
                  </ToolLogoNameContainer>
                  <ToolText screenType={screenType}>
                    {tool.description}
                  </ToolText>
                  {tool.linkText && (
                    <ToolLink screenType={screenType}>
                      {(tool.hasClick && (
                        <a onClick={handleClick}>
                          {tool.linkText}
                          {tool.icon && <img src={tool.icon} alt="tool-icon" />}
                        </a>
                      )) || (
                        <a href={tool.linkHref}>
                          {tool.linkText}
                          {tool.icon && <img src={tool.icon} alt="tool-icon" />}
                        </a>
                      )}
                    </ToolLink>
                  )}
                </ToolWrapper>
              </ToolContainer>
            );
          })}
        </ToolsList>
      </ToolsContainer>
      <ToolsDetail ref={ref}>
        <ToolsReact screenType={screenType}>
          {(screenType !== "mobile" && (
            <SectionTitle screenType={screenType}>
              <span>Developer Tools</span>
              <h3>
                Tools for React{" "}
                <img src={reactLogo} className="react" alt="react-icon" />
              </h3>
            </SectionTitle>
          )) || (
            <SectionTitle screenType={screenType}>
              <h3>
                React components{" "}
                <img src={reactLogo} className="react" alt="react-icon" />
              </h3>
            </SectionTitle>
          )}
          <SectionDescription screenType={screenType}>
            <p>
              React is a Javascript library for building Web user interfaces. It
              helps building reactive apps with encapsulated components that
              manage their own state and thus flattening the learning learning
              curve of composing better UIs.
            </p>
            <p>
              The component-based philosophy of React, provides us with the
              perfect context for distributing a complete suite of React
              components, encapsulating all the visual logic specified in our
              Design Guidelines, and allowing developers to easily create UIs
              that are compliant with these principles without having to deal
              style sheets.
            </p>
          </SectionDescription>
          <SectionToolsNonResponsive screenType={screenType}>
            <DxcCard
              imageSrc={rCDKIcon}
              imagePadding="medium"
              imageBgColor="FFF"
              linkHref="https://developer.dxc.com/halstack"
            >
              <CardContent>
                <h4>React CDK</h4>
                <p>
                  A library of reusable React components encapsulating the
                  visual logic described in the DXC Design Guidelines.
                </p>
              </CardContent>
            </DxcCard>
            <DxcCard
              imageSrc={rHALIcon}
              imagePadding="medium"
              imageBgColor="FFF"
              linkHref="https://github.com/dxc-technology/halstack-react-hal"
            >
              <CardContent>
                <h4>React HAL Components</h4>
                <p>
                  Higher level abstraction on top of the CDK components. It adds
                  logic to exploit, consume and extract data from of Hypermedia
                  REST APIs and map it to UI components.
                </p>
              </CardContent>
            </DxcCard>
          </SectionToolsNonResponsive>
        </ToolsReact>
      </ToolsDetail>
    </React.Fragment>
  );
};

const ToolsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${(props) =>
    props.screenType === "desktop"
      ? "140px 6% 140px 12%"
      : props.screenType === "tablet"
      ? "60px 6% 60px 6%"
      : "34px 20px 40px 20px"};
`;

const ToolsDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.screenType !== "mobile" ? "60px" : "")};
  width: 100%;
`;

const ToolsTitle = styled.div`
  font-size: ${(props) => (props.screenType !== "mobile" ? "48px" : "34px")};
  margin-bottom: 40px;
`;

const ToolsText = styled.div`
  font-size: ${typeface.body.fontSize};
  letter-spacing: ${typeface.body.letterSpacing};
  text-transform: ${typeface.body.textTransform};
  width: ${(props) => (props.screenType === "desktop" ? "61%" : "100%")};
  padding-top: ${(props) => (props.screenType !== "mobile" ? "27px" : "")};
`;

const ToolsList = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "desktop" ? "row" : "column"};
  margin-top: 60px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.screenType === "desktop" ? "100px" : "40px"};
  margin-right: ${(props) => (props.screenType !== "desktop" ? "12%" : "")};
  margin-left: ${(props) => (props.screenType !== "desktop" ? "12%" : "")};
`;

const ToolContainer = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "32%" : "100%")};
  margin-bottom: ${(props) => (props.screenType !== "desktop" ? "60px" : "")};

  &:last-of-type div {
    border-right: none;
  }
`;

const ToolWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: flex-start;
  background-color: ${(props) =>
    props.screenType === "tablet" ? "#F5F5F5" : ""};
  justify-content: ${(props) =>
    props.screenType !== "desktop" ? "center" : ""};
  padding: ${(props) => (props.screenType !== "desktop" ? "36px" : "")};
  ${(props) =>
    props.screenType === "mobile"
      ? "border: 1px solid #DBDBDB; border-radius: 12px;"
      : ""};
`;

const ToolLogoNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.screenType === "mobile" ? "column" : "row"};
  width: 100%;
  margin-bottom: 20px;
`;

const ToolLogo = styled.img`
  width: ${(props) => (props.screenType !== "mobile" ? "31px" : "38px")};
  height: ${(props) => (props.screenType !== "mobile" ? "30px" : "auto")};
  margin-bottom: ${(props) => (props.screenType === "mobile" ? "36px" : "")};
`;

const ToolName = styled.div`
  font-size: 24px;
  margin-left: ${(props) => (props.screenType !== "mobile" ? "16px" : "")};
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.screenType === "mobile"
      ? "text-transform: uppercase; font-weight: 700; font-size: 16px"
      : ""}
`;

const ToolText = styled.div`
  width: 100%;
  font-size: ${typeface.body.fontSize};
  letter-spacing: 0.3px;
  text-transform: ${typeface.body.textTransform};
  padding-bottom: 16px;
  overflow: hidden;
  margin-left: ${(props) => (props.screenType === "desktop" ? "47px" : "")};
  margin-right: ${(props) => (props.screenType === "desktop" ? "12%" : "")};
  padding-right: ${(props) => (props.screenType === "desktop" ? "10%" : "")};
  text-align: ${(props) => (props.screenType === "mobile" ? "justify" : "")};
  color: ${(props) => (props.screenType === "mobile" ? "#666" : "")};

  &:not(:nth-last-child(3)) {
    ${(props) =>
      props.screenType === "desktop" ? "border-right: 1px solid #d9d9d9" : ""};
  }
`;

const ToolLink = styled.div`
  display: flex;
  align-self: flex-end;
  margin-left: ${(props) => (props.screenType === "desktop" ? "47px" : "")};
  a {
    display: inline-flex;
    align-items: center;
    color: #006bf6;
    font-size: 16px;
    letter-spacing: 0.49px;
    text-decoration: none;
    cursor: pointer;
    img {
      margin-left: 12px;
    }
  }
`;

const ToolsDetail = styled.div``;

const ToolsReact = styled.div`
  background-color: ${(props) =>
    props.screenType !== "mobile" ? "#f8f8f8" : ""};
  padding: ${(props) =>
    props.screenType === "desktop" ? "60px 6% 60px 12%" : "60px 6% 60px 6%"};
  padding-top: ${(props) => (props.screenType === "mobile" ? "0" : "")};
  span {
    color: #00000099;
    font-size: ${typeface.overline.fontSize};
    letter-spacing: ${typeface.overline.letterSpacing};
    text-transform: ${typeface.overline.textTransform};
  }
  h3 {
    margin-top: 12px;
    font-size: ${(props) => (props.screenType !== "mobile" ? "34px" : "24px")};
    letter-spacing: 0.24px;
    color: #000000de;
    font-weight: 400;
    border-bottom: ${(props) =>
      props.screenType === "mobile" ? "1px solid #000" : ""};
    padding-bottom: ${(props) => (props.screenType === "mobile" ? "10px" : "")};
  }
`;

const SectionTitle = styled.div`
  h3 {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      margin-left: 20px;
    }
  }
`;

const SectionDescription = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "60%" : "100%")};
  margin-bottom: 40px;
`;

const SectionToolsNonResponsive = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  & > div {
    margin-bottom: ${(props) => (props.screenType !== "desktop" ? "40px" : "")};
  }
`;

const ToolBar = styled.div`
  background-color: #000;
  height: 40px;
`;

const CardContent = styled.div`
  padding-right: 20px;
`;
export default Tools;
