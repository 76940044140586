export const responsiveSizes = {
  mobileSmall: "320",
  mobileMedium: "375",
  mobileLarge: "425",
  tablet: "768",
  laptop: "1024",
  desktop: "1440"
};

export const typeface = {
  body: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    textTransform: "initial"
  },
  altBody: {
    fontSize: "14px",
    letterSpacing: "0.25px",
    textTransform: "initial"
  },
  subtitle: {
    fontSize: "16px",
    letterSpacing: "0.15px",
    textTransform: "initial"
  },
  altSubtitle: {
    fontSize: "14px",
    letterSpacing: "0.1px",
    textTransform: "initial"
  },
  caption: {
    fontSize: "12px",
    letterSpacing: "0.4px",
    textTransform: "initial"
  },
  overline: {
    fontSize: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase"
  }
};
