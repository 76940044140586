import React from "react";
import styled from "styled-components";
import { useScreenType } from "../../common/utils";

const Started = () => {
  const screenType = useScreenType();

  return (
    <StartedContainer screenType={screenType}>
      <TextContainer screenType={screenType}>
        <Titlecontainer>Get Started</Titlecontainer>
        <TextContent>
          <p>
            Halstack Design System is comprised of more than 25 ready-to-use UI
            components. The Component Development Kit (CDK) is available in the
            Web framework React, and because we also think that one size does
            not fit all, we provide theming and UX configurability as a core
            feature in our components.
          </p>
          <p>
            DXC's design and engineering teams have put their passion together
            about building best-in-class User Experiences, getting inspiration
            and guidance from different compelling sources. Material Design, DXC
            Digital Guidelines, and DXC API Guidelines are amongst them,
            resulting in this extraordinary set of UI components that will
            revolutionize how developers build API-driven User Experiences for
            final consumers.
          </p>
        </TextContent>
      </TextContainer>
      <ColumnsContainer screenType={screenType}>
        <ColumnItem screenType={screenType}>
          <span>25+</span>{" "}
          <LabelContent screenType={screenType}>Components</LabelContent>
        </ColumnItem>
      </ColumnsContainer>
    </StartedContainer>
  );
};
const ColumnItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  > p {
    margin-top: 0px;
  }
  span {
    ${(props) =>
      props.screenType === "mobile"
        ? "display: inline-flex; align-items: center; justify-content: center; border-radius: 50px; width: 68px; height: 68px; background-color: #d9d9d9; font-size: 24px; color: #404040;"
        : ""};
    ${(props) =>
      props.screenType === "tablet"
        ? "font-size: 40px;"
        : props.screenType === "mobile"
        ? "font-size: 28px"
        : ""};
  }
`;
const StartedContainer = styled.div`
  width: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
  margin-left: 10%;
  margin-bottom: ${(props) => (props.screenType === "desktop" ? "80px" : "")};
  ${(props) =>
    props.screenType === "tablet"
      ? "flex-direction: column-reverse; margin: 46px 16% 60px 16%; width: calc(100% - 32%);"
      : props.screenType === "mobile"
      ? "flex-direction: column-reverse; margin: 0 40px 60px 40px; width: calc(100% - 80px); align-items: center;"
      : ""};
`;
const TextContainer = styled.div`
  width: ${(props) => (props.screenType !== "desktop" ? "100%" : "42%")};
`;

const Titlecontainer = styled.div`
  font-size: ${(props) =>
    props.screenType === "tablet"
      ? "36px"
      : props.screenType === "mobile"
      ? "24px"
      : "48px"};
  letter-spacing: 0px;
  margin-bottom: 40px;
`;

const TextContent = styled.div``;

const ColumnsContainer = styled.div`
  width: ${(props) => (props.screenType === "tablet" ? "100%" : "46%")};
  font-size: 48px;
  justify-content: center;
  letter-spacing: 0px;
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "tablet" ? "row" : "column"};
  align-items: center;
  ${(props) =>
    props.screenType === "tablet" ? "justify-content: space-evenly;" : ""}
`;

const LabelContent = styled.p`
  color: #66666680;
  font-style: italic;
  font-size: ${(props) => (props.screenType === "mobile" ? "18px" : "20px")};
  letter-spacing: 0.26px;
  margin-bottom: 40px;
`;

export default Started;
