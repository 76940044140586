import React from "react";
import styled from "styled-components";
import Demo from "./Demo";

import { useScreenType } from "../../common/utils";

const Features = () => {
  const screenType = useScreenType();
  return (
    <FeaturesContainer screenType={screenType}>
      <Demo />
    </FeaturesContainer>
  );
};

const FeaturesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-bottom: 130px;
  ${(props) =>
    props.screenType === "tablet"
      ? "flex-wrap: wrap; padding: 0 16%; width: calc(100% - 32%);"
      : props.screenType === "mobile"
      ? "flex-wrap: wrap; padding: 0 40px; width: calc(100% - 80px);"
      : ""};

  & > div {
    width: ${(props) => (props.screenType === "desktop" ? "80%" : "100%")};
  }
`;

export default Features;
