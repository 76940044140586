import React from "react";
import styled from "styled-components";
import {
  getIndustriesArray,
  getDomainsArray,
  getApisArray,
} from "./../../services/api-catalog";
import ApiCard from "./ApiCard";
import { useScreenType } from "../../common/utils";
import { DxcChip } from "@dxc-technology/halstack-react";

const AllApis = ({
  catalog,
  selectedIndustry,
  selectedDomain,
  searchString,
  selectedRegion,
  select,
}) => {
  const screenType = useScreenType();
  const isIndustryVisible = (industry) => {
    return (
      (industry.key === selectedIndustry || !selectedIndustry) &&
      industryContainsApis(industry)
    );
  };

  const industryContainsApis = (industry) => {
    return industry.domains
      .filter((domain) => domain.key === selectedDomain || !selectedDomain)
      .find((domain) => domainContainsApis(domain));
  };

  const domainContainsApis = (domain) => {
    return domain.apis.find(isApiVisible);
  };

  const isDomainVisible = (industry, domain) => {
    return (
      (industry.key === selectedIndustry || !selectedIndustry) &&
      (domain.key === selectedDomain || !selectedDomain) &&
      domainContainsApis(domain)
    );
  };

  const isApiVisible = (api) => {
    return (
      ((searchString &&
        api.title.toLowerCase().includes(searchString.toLowerCase())) ||
        !searchString) &&
      (api.region === selectedRegion ||
        !selectedRegion ||
        selectedRegion === "wor")
    );
  };

  const areApisFound = (catalog) => {
    return (
      catalog &&
      catalog.industries
        .filter(
          (industry) => industry.key === selectedIndustry || !selectedIndustry
        )
        .find((industry) => industryContainsApis(industry))
    );
  };

  return (
    <IndustryList screenType={screenType}>
      {(areApisFound(catalog) &&
        getIndustriesArray(catalog).map((industry, index) => {
          return (
            isIndustryVisible(industry) && (
              <Industry key={`industry-${index}`}>
                <IndustryTitle>{industry.title}</IndustryTitle>
                <DomainList>
                  {getDomainsArray(catalog, industry.key).map(
                    (domain, index) => {
                      return (
                        isDomainVisible(industry, domain) && (
                          <Domain
                            key={`domain-${index}`}
                            screenType={screenType}
                          >
                            <DxcChip label={domain.title} />
                            <ApisList screenType={screenType}>
                              {getApisArray(
                                catalog,
                                industry.key,
                                domain.key
                              ).map((api, index) => {
                                return (
                                  isApiVisible(api) && (
                                    <ApiContent key={`api-${index}`}>
                                      <ApiCard
                                        industry={industry}
                                        domain={domain}
                                        api={api}
                                        select={select}
                                        showDescription={true}
                                      />
                                    </ApiContent>
                                  )
                                );
                              })}
                            </ApisList>
                          </Domain>
                        )
                      );
                    }
                  )}
                </DomainList>
              </Industry>
            )
          );
        })) || (
        <NoApisMessage>No APIs found for this search criteria</NoApisMessage>
      )}
    </IndustryList>
  );
};

const IndustryList = styled.div`
  margin-top: ${(props) => (props.screenType === "mobile" ? "" : "76px")};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Industry = styled.div`
  margin-bottom: 48px;
`;

const IndustryTitle = styled.h3`
  font-size: 20px;
  letter-spacing: 0.26px;
  color: #000000de;
  text-transform: uppercase;
  font-weight: 400;
`;

const DomainList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Domain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
  overflow-y: ${(props) => (props.screenType !== "desktop" ? "auto" : "unset")};
`;

const ApisList = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.screenType === "desktop" ? "wrap" : "")};
`;

const ApiContent = styled.div`
  margin-right: 28px;
  width: 374px;
  height: 176px;
  margin-top: 30px;
`;

const NoApisMessage = styled.div`
  background: #f2f2f2;
  text-transform: uppercase;
  font-size: 25px;
  text-align: center;
  padding: 100px;
  margin-top: 60px;
  font-weight: bold;
  color: #00000054;
`;

export default AllApis;
