import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ApiCard from "./ApiCard";
import arrowLeft from "../../common/images/arrow-left.svg";
import arrowRight from "../../common/images/arrow-right.svg";
import { useScreenType } from "../../common/utils";
import { DxcChip } from "@dxc-technology/halstack-react";

const FeaturedApis = ({ featuredApis, select }) => {
  const screenType = useScreenType();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    swipeToSlide: false,
    lazyLoad: false,
    nextArrow: (
      <ArrowWrapper position="right">
        <RightArrow src={arrowRight} />
      </ArrowWrapper>
    ),
    prevArrow: (
      <ArrowWrapper position="left">
        <LeftArrow src={arrowLeft} />
      </ArrowWrapper>
    ),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      {screenType === "desktop" && (
        <SliderContainer>
          <SliderTitle>Featured content</SliderTitle>
          <DxcChip label="APIS on trend" />
          <SliderContent>
            <Slider {...settings}>
              {featuredApis &&
                featuredApis.map((api, index) => {
                  return (
                    <ApiContent key={`featured-api-${index}`}>
                      <ApiCard
                        {...api}
                        showDescription={true}
                        select={select}
                        onCarousel={true}
                      />
                    </ApiContent>
                  );
                })}
            </Slider>
          </SliderContent>
        </SliderContainer>
      )}
    </React.Fragment>
  );
};

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 100px;
  width: 100%;
`;

const SliderTitle = styled.h3`
  font-size: 20px;
  letter-spacing: 0.26px;
  color: #000000de;
  text-transform: uppercase;
  font-weight: 400;
`;

const SliderContent = styled.div`
  width: 100%;
  max-width: 80vw;
  margin-top: 30px;
  .slick-slider {
    display: grid;
    grid-template-columns: 1fr 20fr 1fr;
    align-items: center;
  }

  .slick-track,
  .slick-slide,
  .slick-slide div {
    outline: none;
    & > div > div > div > div > div {
      background-color: black;
      color: white;
    }
  }

  .slick-dots {
    width: 100%;
    bottom: -40px;
    right: unset;
    left: unset;
  }

  .slick-dots li.slick-active button:before {
    color: #6f2c91;
  }

  .slick-dots li {
    margin: 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  .slick-dots li {
    width: 11px;
    height: 11px;
  }

  .slick-dots li:not(.slick-active) {
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: grey;
    opacity: 0.6;
  }

  .slick-list {
    margin: 0 20px;
  }
`;

const ApiContent = styled.div``;

const ArrowWrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: initial;
  transform: inherit;
  width: 42px;
  height: 42px;
  background-color: lightgrey;
  opacity: 0.8;
  border-radius: 50px;

  &:hover {
    background-color: lightgrey;
    cursor: pointer;
  }

  &:before {
    display: none;
  }
`;

const LeftArrow = styled.img`
  width: ${(props) =>
    props.screenType === "tablet"
      ? "20px"
      : props.screenType === "mobile"
      ? "16px"
      : ""};
`;

const RightArrow = styled.img`
  width: ${(props) =>
    props.screenType === "tablet"
      ? "20px"
      : props.screenType === "mobile"
      ? "16px"
      : ""};
`;

export default React.memo(FeaturedApis);
