import React from "react";
import styled from "styled-components";
import reactLogo from "./images/react-brands.svg";
import {
  DxcLink,
  DxcInset,
  DxcHeading,
  DxcTypography,
  DxcFlex,
} from "@dxc-technology/halstack-react";
import gallery from "../../images/gallery-highlight.png";

const Components = () => {
  return (
    <DxcInset space="5rem">
      <DxcFlex direction="column" gap="3rem" alignItems="center">
        <DxcHeading level={1} text="Components" weight="normal" />
        <DxcTypography fontSize="1.25rem" fontWeight="600" textAlign="center">
          Build superior User Experiences with Halstack Design System
        </DxcTypography>
        <IconContainer>
          <Logo href="https://developer.dxc.com/halstack">
            <ComponentImage src={reactLogo} alt="React logo"></ComponentImage>
          </Logo>
        </IconContainer>
        <DxcLink href={"/design/component-gallery"}>Component Gallery</DxcLink>
        <ComponentImage src={gallery}></ComponentImage>
      </DxcFlex>
    </DxcInset>
  );
};

const Logo = styled.a`
  align-self: center;
`;

const IconContainer = styled.div`
  align-self: center;
`;

const ComponentImage = styled.img`
  width: 100%;
  height: 100%;
`;

export default Components;
