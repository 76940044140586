import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcSpinner,
  DxcPasswordInput,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";
import { login, register, isLoggedIn } from "../services/login.js";
import { useScreenType } from "./utils.js";
import SessionContext from "../SessionContext.js";

const Login = ({ handleLogin, handleRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const screenType = useScreenType();
  const session = useContext(SessionContext);

  const onChangeEmail = ({ value }) => {
    setEmail(value);
  };

  const onChangePassword = ({ value }) => {
    setPassword(value);
  };

  const closeDialog = () => {
    return handleLogin ? handleLogin() : handleRegister();
  };

  const signIn = () => {
    setIsLoading(true);
    setErrorMessage(null);
    login(email, password)
      .then((response) => {
        setErrorMessage(null);
        session.setApiKey(response);
        isLoggedIn() && session.setUsername(isLoggedIn().username);
        handleLogin();
        setIsLoading(false);
      })
      .catch((error) => {
        error.code === "NotAuthorizedException"
          ? setErrorMessage("Incorrect username or password.")
          : setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  const signUp = () => {
    setIsLoading(true);
    setErrorMessage(null);
    register(email, password)
      .then(() => {
        setErrorMessage(null);
        setIsLoading(false);
      })
      .catch((error) => {
        error.code === "UsernameExistsException"
          ? setErrorMessage(
              "Username already exists. Please try again with a different one."
            )
          : error.code === "InvalidPasswordException"
          ? setErrorMessage(
              "Password does not match the requirements. Please try again."
            )
          : error.code === "InvalidParameterException"
          ? setErrorMessage("Username or password incorrect. Please try again.")
          : setErrorMessage(
              "Well, we need to verify that email. Please check your inbox!"
            );
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <DxcDialog
        overlay={true}
        isCloseVisible={true}
        onCloseClick={closeDialog}
      >
        <DxcInset space="2rem">
          <DxcFlex direction="column" gap="3.75rem" alignSelf="center">
            <DxcFlex justifyContent="center" alignItems="center">
              {(handleLogin && (
                <LoginTitle screenType={screenType}>Sign in</LoginTitle>
              )) || <LoginTitle screenType={screenType}>Register</LoginTitle>}
              {isLoading && <DxcSpinner mode="small" />}
            </DxcFlex>
            <DxcFlex direction="column" gap="3.75rem" alignItems="flex-start">
              <DxcFlex
                direction="column"
                gap="1.5rem"
                alignSelf="center"
                alignItems="flex-start"
              >
                <DxcTextInput
                  label="Email"
                  value={email}
                  onChange={onChangeEmail}
                  size={
                    screenType === "desktop"
                      ? "large"
                      : screenType === "tablet"
                      ? "medium"
                      : "fillParent"
                  }
                  clearable
                />
                <DxcPasswordInput
                  label="Password"
                  value={password}
                  onChange={onChangePassword}
                  size={
                    screenType === "desktop"
                      ? "large"
                      : screenType === "tablet"
                      ? "medium"
                      : "fillParent"
                  }
                  clearable
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </DxcFlex>
              <DxcFlex alignSelf="center">
                {handleLogin && (
                  <DxcButton
                    label="SIGN IN"
                    disabled={isLoading}
                    onClick={signIn}
                    size={screenType !== "mobile" ? "large" : "fillParent"}
                  />
                )}
                {handleRegister && (
                  <DxcButton
                    label="SIGN UP"
                    disabled={isLoading}
                    onClick={signUp}
                    size={screenType !== "mobile" ? "large" : "fillParent"}
                  />
                )}
              </DxcFlex>
            </DxcFlex>
          </DxcFlex>
        </DxcInset>
      </DxcDialog>
    </React.Fragment>
  );
};

const LoginTitle = styled.div`
  width: 100%;
  max-width: ${(props) => (props.screenType === "desktop" ? "480px" : "360px")};
  font-size: 34px;
`;

const ErrorMessage = styled.div`
  align-self: center;
  font-size: 14px;
  background-color: #fff6f6;
  color: #9f3a38;
  box-shadow: inset 0 0 0 1px #e0b4b4, 0 0 0 0 transparent;
  padding: 14px 12px;
  border-radius: 5px;
`;

export default Login;
