import React from "react";
import styled from "styled-components";
import people from "./images/intro.png";
import icon from "./images/halstack.svg";
import { typeface } from "../../common/variables.js";
import { useScreenType } from "../../common/utils";

const DesignSystem = () => {
  const screenType = useScreenType();

  return (
    <React.Fragment>
      <DesignSystemContainer screenType={screenType}>
        <LogoAndTextContainer screenType={screenType}>
          <LogoContainer>
            <IconContainer src={icon} screenType={screenType}></IconContainer>
            <HeadingContent screenType={screenType}>
              Halstack Design System
            </HeadingContent>
          </LogoContainer>
          <DesignSystemContent>
            <p>
              Halstack Design System is a set of visual principles, guidelines,
              and tools aimed at helping designers and developers to build
              visually consistent and compelling API-driven User Experiences
              that adhere to our unique branded visual identity. Available as an
              Open Source project, it allows UX teams to manage branding and
              design of applications at scale thanks to a collection of reusable
              UI components for the latest Web development technologies. With
              advanced theming and configurability features, Halstack Design
              System allows designers and developers to create their own themes
              and build up new applications more easily, and at the same time
              leverage our usability, accessibility, layout, and responsiveness
              principles transparently.
            </p>
            <p>
              Additionally, Halstack Design System helps developers in
              flattening the learning curve of building API-driven User
              Experiences. Available as a distributed library, Halstack provides
              a set of higher-order UI components that encapsulates the
              necessary logic to exploit, interact, and extract data from
              hypermedia APIs in a transparent way.
            </p>
            <p>
              Guidelines and UI components are actually the meat of the Halstack
              Design System. On one hand, these UI controls are reusable pieces
              of code from which developers will compose new User Experiences
              more easily and effectively. On the other hand, principles and
              guidelines help closing the circle of the design system, leading
              to actual good design principles for building applications the DXC
              way.
            </p>
            <p>
              Finally, we have prepared this documentation site for designers
              and developers, containing all the necessary information to get
              started with our guidelines and components. We hope you enjoy the
              journey. Welcome to Halstack Design System!
            </p>
          </DesignSystemContent>
        </LogoAndTextContainer>
        {screenType === "desktop" && (
          <ImageContainer>
            <CollageContainer src={people}></CollageContainer>
          </ImageContainer>
        )}
      </DesignSystemContainer>
    </React.Fragment>
  );
};
const DesignSystemContainer = styled.div`
  width: ${(props) =>
    props.screenType === "tablet"
      ? "68%"
      : props.screenType === "mobile"
      ? "calc(100% - 80px)"
      : "calc(100% - 10%)"};
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) =>
    props.screenType === "tablet"
      ? "80px"
      : props.screenType === "mobile"
      ? "96px"
      : "126px"};
  margin-left: ${(props) =>
    props.screenType === "tablet"
      ? "16%"
      : props.screenType === "mobile"
      ? "40px"
      : "10%"};
  margin-bottom: ${(props) =>
    props.screenType === "tablet"
      ? "60px"
      : props.screenType === "mobile"
      ? "36px"
      : "120px"};
  margin-right: ${(props) =>
    props.screenType === "tablet"
      ? "16%"
      : props.screenType === "mobile"
      ? "40px"
      : ""};
`;
const CollageContainer = styled.img`
  width: 100%;
`;

const LogoAndTextContainer = styled.div`
  width: ${(props) => (props.screenType !== "desktop" ? "100%" : "42%")};
`;

const LogoContainer = styled.div``;

const ImageContainer = styled.div`
  width: 46%;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.img`
  width: ${(props) => (props.screenType === "tablet" ? "60px" : "100px")};
  height: ${(props) => (props.screenType === "tablet" ? "auto" : "")};
  ${(props) =>
    props.screenType === "tablet"
      ? "left: 52px;"
      : props.screenType === "mobile"
      ? "left: 40px; top: 128px; width: 45px; height: auto;"
      : ""};
`;

const DesignSystemContent = styled.div`
  font-size: ${typeface.body.fontSize};
  letter-spacing: ${typeface.body.letterSpacing};
`;

const HeadingContent = styled.p`
  font-size: 20px;
  letter-spacing: 0.25px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export default DesignSystem;
