import React, { useContext } from "react";
import styled from "styled-components";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useScreenType } from "../../common/utils";
import SessionContext from "../../SessionContext.js";

const SwaggerSpec = ({ api }) => {
  const screenType = useScreenType();
  const session = useContext(SessionContext);

  return (
    <SwaggerUIContainer screenType={screenType}>
      <SwaggerUI
        spec={api}
        docExpansion="list"
        onComplete={(swaggerUi) => {
          swaggerUi.preauthorizeApiKey("api_key", session.apiKey);
        }}
      />
    </SwaggerUIContainer>
  );
};

export default SwaggerSpec;

const SwaggerUIContainer = styled.div`
  .swagger-ui {
    & * {
      font-family: "Open Sans" !important;
    }
    .info {
      margin: 0 !important;
      .title {
        display: none;
      }
    }
    .wrapper {
      max-width: initial;
      padding: 0px;
      section.models {
        border: none;
        div.model-container {
          margin: 20px 0;
        }
        span.model-box {
          padding: 6px;
        }
        h4 svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    h4.opblock-tag {
      display: flex;
      flex-wrap: ${(props) =>
        props.screenType === "desktop" ? "nowrap" : "wrap"};
      align-items: stretch;
      padding: 10px 20px 10px 10px !important;
      border-bottom: 1px solid #d9d9d9;
      a {
        width: ${(props) => (props.screenType === "desktop" ? "auto" : "100%")};
        span {
          font-size: 16px;
          text-transform: capitalize;
        }
      }
      small {
        padding-left: ${(props) => (props.screenType !== "desktop" ? "0" : "")};
      }
      p {
        font-size: 12px;
      }
    }
    .expand-operation svg {
      width: 16px;
      height: 16px;
    }

    div.information-container {
      div.description p {
        font-size: 16px;
      }
      .ui.fluid.left.aligned.container.fixfloat {
        padding-top: 0px !important;
      }
      .information-container-content {
        & > div {
          display: flex;
          flex-wrap: wrap;
        }
        h1 {
          font-size: 35px;
          font-weight: 100;
        }
        .information-endpoint,
        .information-version {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .information-version {
          margin-right: 20px;
          width: fit-content;
          height: fit-content;
          padding: 10px;
          margin: 0 20px;
          background-color: black;
          color: yellow;
          font-weight: 100;
          font-size: 14px;
          text-transform: uppercase;
          p:first-of-type {
            font-weight: normal !important;
            margin-bottom: 0;
            margin-right: 5px;
          }
        }

        .information-endpoint {
          p:first-of-type {
            margin-right: 15px;
            font-size: 18px;
            margin-bottom: 0;
          }

          p:last-of-type {
            font-size: 16px;
          }
        }
      }
    }

    div.information-container + div {
      &.scheme-container {
        box-shadow: none;
        div.auth-wrapper button {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          text-transform: uppercase;
          color: white;
          background-color: #6F2C91;
          border: 2px solid #6F2C91;
          padding: 10px 28px;
          span {
            padding: 0;
            margin-right: 10px;
          }
          svg {
            fill: white;
            width: 18px;
            height: 18px;
          }

          &:hover {
            box-shadow: none;
            background-color: white;
            color: #6F2C91;
            border: 2px solid #6F2C91;
            
            svg {
              fill: #6F2C91;
            }
          }
        }
      }
      select {
        border-radius: 0;
        box-shadow: none;
      }
    }

    div.opblock.opblock-get,
    div.opblock.opblock-options,
    div.opblock.opblock-post,
    div.opblock.opblock-patch,
    div.opblock.opblock-delete,
    div.opblock.opblock-put {
      border: none;
      border-radius: 0;
      min-height: 46px;
      box-shadow: none;
      margin-bottom: 20px;
      &.is-open {
        .opblock-summary {
          min-height: 47px;
        }
      }
      .opblock-summary {
        padding: 0;
        min-height: 46px;
        border-color: none;
        align-items: stretch;
        height: 100%;
        .opblock-summary-method {
          min-width: 110px;
          border-radius: 0;
          color: #000000 !important;
          font-size: 14px;
          padding: 10px 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        .opblock-summary-path {
          font-size: 14px;
          color: #000000;
          padding: 0 20px;
          font-weight: normal;
          span {
            font-size: 14px;
            word-break: ${(props) =>
              props.screenType === "mobile" ? "normal" : "break-word"};
          }
        }
        .opblock-summary-description {
          display: inline-flex;
          align-items: center;
          color: #00000099;
          font-size: 12px;
        }

        & + div div.opblock-section-header label {
          flex-direction: ${(props) =>
            props.screenType === "mobile" ? "column" : "row"};
        }
      }
    }

    div.opblock.opblock-get,
    div.opblock.opblock-options {
      background-color: #b2efff;
      .opblock-summary {
        border-color: #ffffffcc;
      }
      .opblock-summary-method {
        background-color: #7fe4ff;
      }
    }

    div.opblock.opblock-post,
    div.opblock.opblock-patch {
      background-color: rgba(100, 255, 0, 0.5);
      .opblock-summary {
        border-color: #64ff00;
        .opblock-summary-method {
          background-color: #64ff00;
          color: #ffffff;
        }
      }
    }

    div.opblock.opblock-delete,
    div.opblock.opblock-put {
      background-color: rgba(0, 0, 0, 0.5);
      .opblock-summary {
        border-color: #000000;
        .opblock-summary-method {
          background-color: #000000;
          color: #ffffff !important;
        }
        .opblock-summary-description {
          color: #ffffff;
        }
        .opblock-summary-path {
          color: #ffffff;
        }
      }
      .opblock-body div.table-container * {
        /* color: #ffffff !important; */
      }
    }

    .opblock-tag-section {
      margin: 12px 0;
      .opblock-tag {
        padding: 0;
      }
    }

    .try-out__btn {
      padding: 12px 30px;
      border: none;
      background-color: #ffed00;
      text-transform: uppercase;
      font-weight: 600;
      box-shadow: none;
      &:hover {
        background-color: #000;
        color: #ffed00;
        box-shadow: none;
      }
    }
  }
`;
