import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { DxcButton } from "@dxc-technology/halstack-react";
import { useScreenType } from "../common/utils";
import { useHistory } from "react-router-dom";

import mouse from "./images/mouse.svg";
import desktop from "./images/desktop.svg";

import { typeface } from "../common/variables.js";

const Discover = () => {
  const history = useHistory();

  const onClick = () => {
    history.push("/getting-started");
  };

  const screenType = useScreenType();

  return (
    <StyledDiscover screenType={screenType}>
      {screenType !== "desktop" && (
        <DiscoverTitleMobile>
          <p>About the portal</p>
        </DiscoverTitleMobile>
      )}
      <DiscoverDescription screenType={screenType}>
        <DiscoverDescriptionContent screenType={screenType}>
          <DiscoverDescriptionTitle screenType={screenType}>
            Design System
          </DiscoverDescriptionTitle>
          <DiscoverDescriptionText screenType={screenType}>
            Connect with the DXC development community and learn how to build
            new products using DXC APIs. Get access to development tutorials and
            code labs and be the first to learn about DXC new releases.
          </DiscoverDescriptionText>
          {screenType !== "mobile" && (
            <DxcButton
              label="Get started with halstack"
              onClick={onClick}
              size="fitContent"
            />
          )}
        </DiscoverDescriptionContent>
      </DiscoverDescription>
      <DiscoverItems screenType={screenType}>
        <DiscoverItemsContent screenType={screenType}>
          <DiscoverItem screenType={screenType}>
            <Link to="/design">
              <DiscoverItemIconContainer screenType={screenType}>
                <DiscoverItemIcon
                  src={mouse}
                  alt="getting started icon"
                  screenType={screenType}
                />
              </DiscoverItemIconContainer>
              <DiscoverItemText screenType={screenType}>
                Overview
              </DiscoverItemText>
            </Link>
          </DiscoverItem>
          <DiscoverItem screenType={screenType}>
            <Link to="/design/tools">
              <DiscoverItemIconContainer screenType={screenType}>
                <DiscoverItemIcon
                  src={desktop}
                  alt="visual icon"
                  screenType={screenType}
                />
              </DiscoverItemIconContainer>
              <DiscoverItemText screenType={screenType}>Tools</DiscoverItemText>
            </Link>
          </DiscoverItem>
        </DiscoverItemsContent>
      </DiscoverItems>
      {screenType === "mobile" && (
        <DxcButton
          label="Start exploring the portal"
          onClick={onClick}
          size="fillParent"
          margin="medium"
        />
      )}
    </StyledDiscover>
  );
};

const StyledDiscover = styled.div`
  display: flex;
  background: transparent linear-gradient(89deg, #000000 0%, #1f2020 100%) 0% 0%
    no-repeat padding-box;
  width: ${(props) => (props.screenType !== "desktop" ? "auto" : "100%")};
  height: ${(props) => (props.screenType !== "mobile" ? "823px" : "auto")};
  ${(props) =>
    props.screenType !== "desktop" ? "flex-direction: column" : ""};
  ${(props) =>
    props.screenType === "mobile"
      ? "padding: 80px 40px 100px 40px"
      : props.screenType === "tablet"
      ? "padding: 120px 80px 140px 80px"
      : ""};
  position: relative;
`;

const DiscoverDescription = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "45%" : "100%")};
  display: flex;
  padding-left: ${(props) => (props.screenType === "desktop" ? "10%" : "0")};
  padding-top: ${(props) => (props.screenType === "desktop" ? "221px" : "0")};
`;

const DiscoverDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.screenType !== "tablet" ? "unset" : "500px")};
  color: white;
  align-items: flex-start;
`;

const DiscoverDescriptionTitle = styled.div`
  font-size: ${(props) => (props.screenType !== "mobile" ? "48px" : "34px")};
  color: ${(props) => (props.screenType !== "mobile" ? "#FFFFFFDE" : "")};
  font-weight: ${(props) => (props.screenType === "mobile" ? "300" : "")};
  letter-spacing: 0px;
  margin-bottom: 40px;
`;

const DiscoverDescriptionText = styled.div`
  font-size: ${typeface.body.fontSize};
  letter-spacing: ${typeface.body.letterSpacing};
  text-transform: ${typeface.body.textTransform};
  margin-bottom: 60px;
  font-weight: ${(props) => (props.screenType === "mobile" ? "300" : "")};
`;

const DiscoverItems = styled.div`
  width: ${(props) => (props.screenType === "desktop" ? "55%" : "100%")};
  ${(props) => (props.screenType === "tablet" ? "padding-top: 140px" : "")};
  ${(props) =>
    props.screenType === "desktop" ? "display:flex; align-items: center" : ""};
  box-sizing: ${(props) => (props.screenType === "tablet" ? "border-box" : "")};
  font-weight: ${(props) => (props.screenType !== "desktop" ? "300" : "")};
`;

const DiscoverItemsContent = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.screenType === "desktop" ? "column" : "row"};
  ${(props) => (props.screenType === "mobile" ? "flex-wrap: wrap" : "")};
  flex-wrap: ${(props) => (props.screenType === "tablet" ? "wrap" : "")};
  margin-left: ${(props) => (props.screenType === "desktop" ? "35%" : "")};
`;

const DiscoverItem = styled.div`
  ${(props) => (props.screenType !== "desktop" ? "width: 50%" : "")};
  margin-bottom: 67px;
  & a {
    display: flex;
    align-items: center;
    ${(props) =>
      props.screenType !== "desktop" ? "flex-direction: column" : ""};
    text-decoration: none;
  }
`;

const DiscoverItemIconContainer = styled.div`
  height: 72px;
  width: 72px;
  background: ${(props) =>
    props.screenType !== "mobile" ? "white" : "#EBEBEB"};
  margin-right: ${(props) => (props.screenType === "desktop" ? "40px" : "")};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
`;

const DiscoverItemIcon = styled.img`
  width: ${(props) => (props.screenType === "mobile" ? "32px" : "")};
  &:first-of-type {
    height: ${(props) => (props.screenType === "mobile" ? "38px" : "")};
  }
`;

const DiscoverItemText = styled.div`
  color: white;
  font-size: ${(props) =>
    props.screenType === "desktop"
      ? "24px"
      : props.screenType === "tablet"
      ? "18px"
      : typeface.altSubtitle.fontSize};
  letter-spacing: ${(props) =>
    props.screenType !== "mobile" ? "0" : typeface.altSubtitle.letterSpacing};
  margin-top: ${(props) => (props.screenType !== "desktop" ? "12px" : "")};
  text-align: ${(props) => (props.screenType !== "desktop" ? "center" : "")};
`;

const DiscoverTitleMobile = styled.div`
  position: absolute;
  top: -17px;
  left: 0;
  padding: 0 40px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  p {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.88px;
    color: #00000099;
  }
`;

export default Discover;
