import React from "react";
import styled from "styled-components";
import { typeface } from "./../../common/variables.js";

const SideNav = ({
  industries,
  selectedIndustry,
  selectedDomain,
  searchString,
  selectedRegion,
  select,
}) => {
  const getNumberApisFiltered = (domain, searchString, selectedRegion) => {
    return (
      ((searchString === "" || searchString == null) &&
        selectedRegion == null &&
        domain.apis.length) ||
      (searchString !== "" &&
        searchString != null &&
        selectedRegion == null &&
        domain.apis.filter(
          (api) =>
            searchString &&
            api.title.toLowerCase().includes(searchString.toLowerCase())
        ).length) ||
      ((searchString === "" || searchString == null) &&
        selectedRegion != null &&
        selectedRegion !== "wor" &&
        domain.apis.filter((api) => api.region === selectedRegion).length) ||
      ((searchString === "" || searchString == null) &&
        selectedRegion != null &&
        selectedRegion === "wor" &&
        domain.apis.length) ||
      domain.apis.filter(
        (api) =>
          searchString &&
          api.title.toLowerCase().includes(searchString.toLowerCase()) &&
          (api.region === selectedRegion || selectedRegion === "wor")
      ).length
    );
  };

  return (
    <SideNavContainer>
      <SideNavTitle>API Catalog</SideNavTitle>
      <Industry>
        <AllCategoriesIndustry
          isSelected={!selectedIndustry && !selectedDomain}
          onClick={() =>
            (select && select({ industry: null, domain: null, api: null })) ||
            ""
          }
        >
          All Categories
        </AllCategoriesIndustry>
      </Industry>

      {industries &&
        industries.map((industry, index) => {
          return (
            <IndustryList key={`industry-${index}`}>
              <Industry>
                <IndustryTitle
                  isSelected={selectedIndustry === industry.key}
                  onClick={() =>
                    (select &&
                      select({
                        industry: industry.key,
                        domain: null,
                        api: null,
                      })) ||
                    ""
                  }
                >
                  {industry.title}
                </IndustryTitle>
                <DomainList>
                  {industry.domains &&
                    industry.domains.map((domain, index) => {
                      return (
                        <Domain key={`domain-${index}`}>
                          <DomainName
                            isSelected={selectedDomain === domain.key}
                            onClick={() =>
                              (select &&
                                select({
                                  industry: industry.key,
                                  domain: domain.key,
                                  api: null,
                                })) ||
                              ""
                            }
                          >
                            {domain.title}
                          </DomainName>
                          <ApisNumberBadge
                            num={getNumberApisFiltered(
                              domain,
                              searchString,
                              selectedRegion
                            )}
                          ></ApisNumberBadge>
                        </Domain>
                      );
                    })}
                </DomainList>
              </Industry>
            </IndustryList>
          );
        })}
    </SideNavContainer>
  );
};

const ApisNumberBadge = ({ num }) => {
  return <ResultsNumber num={num}>{num}</ResultsNumber>;
};

const ResultsNumber = styled.span`
  min-width: calc(18px - 10px);
  height: 18px;
  background-color: #666666;
  color: white;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  line-height: 2px;
  font-size: 14px;
`;

const SideNavContainer = styled.div``;

const SideNavTitle = styled.div`
  font-size: 24px;
  color: #000000de;
  font-weight: ${({ isSelected }) => (isSelected && "bold") || "normal"};
`;

const IndustryList = styled.div`
  color: #00000099;
  font-size: ${typeface.altBody.fontSize};
  letter-spacing: ${typeface.altBody.letterSpacing};
  text-transform: ${typeface.altBody.textTransform};
`;

const Industry = styled.div``;

const AllCategoriesIndustry = styled.h2`
  font-size: 14px;
  color: #00000099;
  letter-spacing: 1.88px;
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
  font-weight: ${({ isSelected }) => (isSelected && "bold") || "normal"};
`;

const IndustryTitle = styled.h2`
  font-size: 14px;
  color: #00000099;
  letter-spacing: 1.88px;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 40px;
  font-weight: 600;
  cursor: pointer;
  font-weight: ${({ isSelected }) => (isSelected && "bold") || "normal"};
`;

const DomainList = styled.div``;

const Domain = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DomainName = styled.span`
  font-size: 14px;
  color: #000000;
  padding-left: 18px;
  margin-right: 22px;
  cursor: pointer;
  font-weight: ${({ isSelected }) => (isSelected && "bold") || "normal"};
  & + span {
    ${(props) =>
      props.isSelected
        ? "background-color: #6F2C91; color: #fff; font-weight: 600; font-size: 14.5px"
        : ""};
  }
`;

export default SideNav;
