import React, { useState, useContext } from "react";
import styled from "styled-components";
import SessionContext from "../SessionContext.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "@material-ui/core";
import copyIcon from "../images/file_copy.svg";

const Dashboard = () => {
  const [clipboardText, setClipboardText] = useState("Copy to clipboard");

  const session = useContext(SessionContext);

  const onCopy = () => {
    setClipboardText("Copied");
  };

  return (
    <DashboardContent>
      <WelcomeText>Welcome, {session.username}!</WelcomeText>
      <ApiKeyTitle>Your API Key</ApiKeyTitle>
      <ApiKeyInfo>
        An API key identifies you as a Developer Central user and allows you to
        interact with the APIs. Below you can find your personal key. For more
        information on how to use it in your application code, please see API
        codelabs.
      </ApiKeyInfo>
      <ApiKey>
        <code>{session.apiKey}</code>
        <CopyToClipboard onCopy={onCopy} text={session.apiKey}>
          <Tooltip title={clipboardText}>
            <CopyIcon src={copyIcon} />
          </Tooltip>
        </CopyToClipboard>
      </ApiKey>
      <br />
      <ApiKeyMoreInfo>
        We almost forgot! As a signed user, Developer Central passes your key to
        every API call automatically and transparently for you, so you don't
        have to do it manually in the portal.
      </ApiKeyMoreInfo>
    </DashboardContent>
  );
};

const DashboardContent = styled.div`
  padding: 5% 10%;
`;

const WelcomeText = styled.h2``;

const ApiKeyTitle = styled.h2``;

const ApiKeyInfo = styled.div`
  font-size: 19px;
  line-height: 1.4285em;
`;

const ApiKey = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 30px;
  margin: 50px;
  code {
    padding: 10px 30px 10px 10px;
    color: black;
    border-radius: "0px";
    font-size: 150%;
  }
`;

const ApiKeyMoreInfo = styled.div`
  font-size: 19px;
  line-height: 1.4285em;
`;

const CopyIcon = styled.img`
  height: 22px;
  width: 22px;
  padding-top: 12px;
  cursor: pointer;
`;

export default Dashboard;
