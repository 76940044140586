import React from "react";
import styled from "styled-components";

import DesignSystem from "./DesignSystem";
import Started from "./Started";
import Others from "./Others";
import Components from "./Components";
import Technologies from "./Technologies";
import Features from "./Features";
import Contanct from "./Contact";

const Overview = () => {
  return (
    <OverviewContainer>
      <DesignSystem />
      <Started />
      <Others />
      <Components />
      <Technologies />
      <Features />
      <Contanct />
    </OverviewContainer>
  );
};

const OverviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default Overview;
