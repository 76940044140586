import React from "react";
import styled from "styled-components";

const FooterContent = () => {
  const contentList = [
    {
      title: "",
      links: [
        { name: "About DXC", href: "http://www.dxc.technology/about_us" },
        {
          name: "DXC Github",
          href: "https://github.com/dxc-technology",
        },
        {
          name: "DXC Labs",
          href: "http://www.dxc.technology/innovation#tabs-4",
        },
        {
          name: "DXC Platform X",
          href: "http://www.dxc.technology/bionix",
        },
      ],
    },
    {
      title: "About Us",
      links: [
        {
          name: "Contact Us",
          href: "mailto:dxc-assure-hotline@dxc.com",
        },
        {
          name: "Thought Leadership and Community",
          href: "http://thrive.dxc.com/",
        },
        {
          name: "Blogs",
          href: "http://blogs.dxc.com/",
        },
        {
          name: "Give your feedback",
          href: "http://www.dxc.technology/contact_us/aut/105649-survey_questions",
        },
      ],
    },
  ];
  return (
    <FooterContentContainer>
      {contentList.map((item) => {
        return (
          <FooterContentColumn key={item.title}>
            <FooterColumnTitle>
              <LinkFooter href={item.href}>{item.title}</LinkFooter>
            </FooterColumnTitle>
            <FooterColumnList>
              {item.links.map((link) => {
                return (
                  <FooterColumnItem key={link.name}>
                    <LinkFooter href={link.href}>{link.name}</LinkFooter>
                  </FooterColumnItem>
                );
              })}
            </FooterColumnList>
          </FooterContentColumn>
        );
      })}
    </FooterContentContainer>
  );
};

const FooterContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterContentColumn = styled.div``;

const FooterColumnList = styled.ul`
  list-style-type: none;
  padding-left: 0px;
`;

const FooterColumnItem = styled.li``;

const FooterColumnTitle = styled.p`
  font-weight: bold;
`;

const LinkFooter = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;
`;

export default FooterContent;
