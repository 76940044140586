import React, { useState } from "react";
import styled from "styled-components";
import { DxcTextInput, DxcButton } from "@dxc-technology/halstack-react";
import { useScreenType } from "../../common/utils";

const SetUpApiKey = ({ apiTitle, onSetApiKey }) => {
  const screenType = useScreenType();
  const [inputValue, setInputValue] = useState("");

  return (
    <StyledWrapper>
      <h1>Set up an API Key</h1>
      <StyledParagraph>
        {apiTitle
          ? `You need a key to browse ${apiTitle} API safely. A key identifies you as a Developer Central user and allows you to interact with the APIs. You can get an API key by signing in DXC Developer Central. If you already have one, enter the key in the 
          field below and click the button to start browsing this API.`
          : `You need a key to see the API status console. A key identifies you as a Developer Central user and allows you to interact with the APIs. You can get an API key by signing in DXC Developer Central. If you already have one, enter the key in the field below and click the 
            button to start browsing this API.`}
      </StyledParagraph>
      <FormContainer screenType={screenType}>
        <DxcTextInput
          label="API Key"
          value={inputValue?.value}
          onChange={setInputValue}
          size={screenType === "desktop" ? "large" : "fillParent"}
          margin="small"
          clearable
        />
        <ButtonContainer>
          <DxcButton
            label="Use API Key"
            size={screenType === "desktop" ? "large" : "fillParent"}
            onClick={() => {
              onSetApiKey(inputValue?.value);
            }}
            margin="small"
          />
        </ButtonContainer>
      </FormContainer>
    </StyledWrapper>
  );
};

export default SetUpApiKey;

const StyledWrapper = styled.div`
  h1 {
    font-size: 34px;
    font-weight: 400;
    margin-top: 0;
  }
`;

const StyledParagraph = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 22px;
  letter-spacing: 0.48px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: ${(props) =>
    props.screenType === "desktop" ? "row" : "column"};
  justify-content: center;
  background-color: #f2f2f2;
`;

const ButtonContainer = styled.div`
  margin-top: 5px;
`;
