import React from "react";
import styled from "styled-components";
import laptop from "./images/laptop.svg";
import backpack from "./images/backpack.svg";

import { useScreenType } from "../../common/utils";
import { DxcLink } from "@dxc-technology/halstack-react";

const Others = () => {
  const screenType = useScreenType();

  return (
    <OthersContainer screenType={screenType}>
      <OthersItem screenType={screenType}>
        <p>Tools for designers</p>
        <ImageDivContainer>
          <ImageContainer src={laptop}></ImageContainer>
        </ImageDivContainer>
        <OthersDescription>
          Our tools for designing UIs the Halstack way, as per our principles.
          This is for you, designers.
        </OthersDescription>
        <DxcLink href={"/design/tools"}>Let's design a new experience</DxcLink>
      </OthersItem>
      <OthersItem screenType={screenType}>
        <p>Tools for developers</p>
        <ImageDivContainer>
          <ImageContainer src={backpack}></ImageContainer>
        </ImageDivContainer>
        <OthersDescription>
          Our tools for application developers to start building cool
          applications. Developers, check them out!
        </OthersDescription>
        <DxcLink href={"/design/tools"}>Let's build an application</DxcLink>
      </OthersItem>
    </OthersContainer>
  );
};

const OthersContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #fbfbfb;
  justify-content: space-evenly;
  padding-left: ${(props) => (props.screenType === "tablet" ? "20%" : "6%")};
  padding-right: ${(props) => (props.screenType === "tablet" ? "20%" : "6%")};
  padding-bottom: 120px;
  padding-top: 88px;
  ${(props) =>
    props.screenType === "tablet"
      ? "justify-content: space-between; flex-wrap: wrap; padding-bottom: 80px;"
      : ""};
  ${(props) =>
    props.screenType === "mobile"
      ? "padding: 80px 62px; 80px 40px; flex-wrap: wrap;"
      : ""};
`;
const OthersItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: ${(props) =>
    props.screenType === "tablet"
      ? "40%"
      : props.screenType === "mobile"
      ? "100%"
      : "16%"};
  align-items: flex-start;
  ${(props) =>
    props.screenType === "tablet"
      ? "margin-bottom: 80px"
      : props.screenType === "mobile"
      ? "margin-bottom: 60px"
      : ""};

  & > p:first-of-type {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;
const ImageContainer = styled.img``;
const OthersDescription = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;
const ImageDivContainer = styled.div`
  border-radius: 50%;
  background-color: #ebebeb;
  display: inline-flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
`;

export default Others;
