import React from "react";
import styled from "styled-components";
import { useScreenType } from "../../common/utils";
import githubLogo from "./images/github.svg";

const Contact = () => {
  const screenType = useScreenType();

  return (
    <ContactContainer>
      <TitleContainer screenType={screenType}>
        If you need more information, <br />
        send us a message on Github
      </TitleContainer>
      <FormContainer screenType={screenType}>
        <DesignGithub href="https://dxc-technology.github.io/halstack/">
          <img src={githubLogo} alt="github-icon" />
          <p>Halstack Info Page</p>
        </DesignGithub>
        <DevelopGithub href="https://github.com/dxc-technology/halstack-react">
          <img src={githubLogo} alt="github-icon" />
          <p>Halstack repository</p>
        </DevelopGithub>
      </FormContainer>
    </ContactContainer>
  );
};

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #666666;
  align-items: center;
  padding: 80px 0;
  height: 480px;
`;

const TitleContainer = styled.p`
  width: 100%;
  font-size: ${(props) => (props.screenType === "mobile" ? "24px" : "34px")};
  letter-spacing: 0.24px;
  color: #fff;
  line-height: ${(props) => (props.screenType === "mobile" ? "28px" : "46px")};
  text-align: center;
  margin: 0;
`;

const FormContainer = styled.div`
  display: flex;
  width: ${(props) =>
    props.screenType === "desktop"
      ? "40%"
      : props.screenType === "tablet"
      ? "60%"
      : props.screenType === "mobile"
      ? "80%"
      : ""};
  justify-content: space-around;

  & > span {
    font-size: 14px;
  }
`;

const DesignGithub = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  p {
    color: #fff;
  }
`;

const DevelopGithub = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  p {
    color: #fff;
  }
`;

export default Contact;
