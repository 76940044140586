import axios from "axios";

export function getApiCatalog() {
  return axios({
    method: "GET",
    url: `https://developer.dxc.com/apiCatalog/catalog.json`,
    responseType: "json",
  }).then((response) => response.data);
}

export function getIndustriesArray(catalog) {
  return (catalog && catalog.industries) || [];
}

export function findDomain(catalog, industryId, domainId) {
  const domains = getDomainsArray(catalog, industryId);
  return domains.find((domain) => domain.key === domainId);
}

export function getDomainsArray(catalog, industryId) {
  const industry = findIndustry(catalog, industryId);
  return (industry && industry.domains) || [];
}

export function findIndustry(catalog, industryId) {
  const industries = getIndustriesArray(catalog);
  return industries.find((industry) => industry.key === industryId);
}

export function getApisArray(catalog, industryId, domainId) {
  const domain = findDomain(catalog, industryId, domainId);
  return (domain && domain.apis) || [];
}

export function findApi(catalog, industryId, domainId, apiId) {
  const apis = getApisArray(catalog, industryId, domainId);
  return apis.find((api) => api.key === apiId);
}

export function getApiResource(api, resourceId, extension) {
  return axios({
    method: "GET",
    url: `https://developer.dxc.com/${api.path}${resourceId}.${extension}`,
  }).then((response) => response.data);
}

export function getFeaturedList(catalog) {
  const featuredApis = [];
  getIndustriesArray(catalog).forEach((industry) =>
    getDomainsArray(catalog, industry.key).forEach((domain) =>
      getApisArray(catalog, industry.key, domain.key).forEach((api) => {
        if (api.featured) {
          featuredApis.push({
            api,
            industry,
            domain,
          });
        }
      })
    )
  );
  return featuredApis;
}

export const getApisFromCatalog = (catalog) =>
  catalog.industries.reduce(
    (list, industry) => [...list, ...getApisFromIndustry(industry)],
    []
  );

export const getApisFromDomain = (domain) =>
  domain.apis.map((api) => ({
    title: api.title,
    key: api.key,
    path: api.path,
    domainTitle: domain.title,
    domainKey: domain.key,
  }));

export const getApisFromIndustry = (industry) =>
  industry.domains.reduce(
    (list, domain) => [
      ...list,
      ...getApisFromDomain(domain).map((api) => ({
        ...api,
        industryTitle: industry.title,
        industryKey: industry.key,
      })),
    ],
    []
  );
