import React from "react";
import styled from "styled-components";
import { DxcTextInput, DxcSelect } from "@dxc-technology/halstack-react";
import { useScreenType } from "../../common/utils";

const SearchApis = ({
  searchString,
  selectedRegion,
  onChangeSearchString,
  selectRegion,
}) => {
  const screenType = useScreenType();
  const regionOptions = [
    {
      value: "wor",
      label: "Worldwide",
    },
    {
      value: "amer",
      label: "Americas",
    },
    {
      value: "eu",
      label: "Europe",
    },
    {
      value: "apac",
      label: "Asia-Pacific",
    },
  ];

  return (
    <React.Fragment>
      {screenType !== "mobile" ? (
        <SearchArea>
          <DxcTextInput
            label="Search for a product..."
            value={searchString || ""}
            onChange={onChangeSearchString}
            clearable
            margin={{
              top: "xsmall",
              right: "medium",
              bottom: "medium",
              left: "large",
            }}
          />
          <DxcSelect
            label="Region"
            options={regionOptions}
            onChange={selectRegion}
            value={selectedRegion || "wor"}
            margin={{
              top: "xsmall",
              right: "medium",
              bottom: "medium",
              left: "large",
            }}
          />
        </SearchArea>
      ) : (
        <React.Fragment>
          <Decoration></Decoration>
          <SearchArea screenType={screenType}>
            <DxcTextInput
              label="Search for a product..."
              value={searchString || ""}
              onChange={onChangeSearchString}
              size="fillParent"
              margin="small"
              clearable
            />
          </SearchArea>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const SearchArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  background: ${(props) =>
    props.screenType === "mobile" ? "transparent" : "#d9d9d9"};
`;

const Decoration = styled.div`
  height: 40px;
  background-color: #000;
  width: 100%;
`;

export default SearchApis;
