import React from "react";
import styled from "styled-components";
import { DxcCard } from "@dxc-technology/halstack-react";
import { typeface } from "./../../common/variables.js";
import apiIcon from "../../images/api-icon.png";

const ApiCard = ({
  industry,
  domain,
  api,
  select,
  showDescription,
  onCarousel,
}) => {
  return (
    <ApiCardContainer>
      <DxcCard
        margin={
          api.featured && onCarousel ? { left: "xsmall", right: "xsmall" } : ""
        }
        imageCover={false}
        imageBgColor={api.featured && onCarousel ? "black" : ""}
        imageSrc={
          (api &&
            api.icon &&
            `https://developer.dxc.com/${api.path}images/${api.icon}`) ||
          apiIcon
        }
        onClick={() =>
          (select &&
            select({
              industry: industry.key,
              domain: domain.key,
              api: api.key,
            })) ||
          ""
        }
      >
        <ApiTitle>{api.title}</ApiTitle>
        {showDescription && <ApiDescription>{api.description}</ApiDescription>}
      </DxcCard>
    </ApiCardContainer>
  );
};

const ApiCardContainer = styled.div`
  cursor: pointer;
  & > div,
  & > div > div > div {
    align-items: flex-start;
  }
  & > div > div > div {
    width: 340px;
    height: 148px;
  }

  & > div > div > div > div:first-of-type {
    width: 54px;
    display: flex;
    align-items: flex-start;
    img {
      background-color: white;
      min-width: 28px;
      min-height: 28px;
      width: 28px;
      height: 28px;
      padding: 8px;
      margin-left: 20px;
      border-radius: 10px;
      margin-top: 24px;
      object-fit: contain;
    }
  }
  & > div > div > div > div:last-of-type {
    margin: 20px;
  }
`;

const ApiTitle = styled.h2`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ApiDescription = styled.p`
  font-size: ${typeface.altSubtitle.fontSize};
  letter-spacing: ${typeface.altSubtitle.letterSpacing};
  text-transform: ${typeface.altSubtitle.textTransform};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export default ApiCard;
