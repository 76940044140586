import React from "react";
import styled from "styled-components";
import {
  DxcTypography,
  DxcCard,
  DxcFlex,
  DxcInset,
  HalstackProvider,
} from "@dxc-technology/halstack-react";
import * as miniatures from "../imagesCatalog";

const CardComponent = ({ component, version }) => {
  const advancedTheme = {
    card: {
      height: "300px",
      width: "300px",
    },
  };

  return (
    <HalstackProvider advancedTheme={advancedTheme}>
      <DxcCard linkHref={`/halstack/${version}/components/${component.key}/`}>
        <DxcInset space="1.5rem">
          <DxcFlex direction="column">
            <DxcTypography color="#666666" fontWeight="600">
              {component.name}
            </DxcTypography>
            <ComponentImage
              src={
                miniatures[`dxc${component.key.replace("-", "")}`] ||
                miniatures["imgDefault"]
              }
            />
          </DxcFlex>
        </DxcInset>
      </DxcCard>
    </HalstackProvider>
  );
};

const ComponentImage = styled.img`
  width: 250px;
  height: 250px;
`;

export default CardComponent;
