import React from "react";
import styled from "styled-components";

import demo from "./images/demo.png";
import demoTablet from "./images/demo_ipad.svg";

import { useScreenType } from "../../common/utils";
import { DxcButton } from "@dxc-technology/halstack-react";

const Demo = () => {
  const screenType = useScreenType();

  return (
    <React.Fragment>
      {screenType !== "laptop" && (
        <DemoContainer screenType={screenType}>
          {screenType !== "desktop" && (
            <SubtitleContainer>
              Are you ready to see all the potencial of our design system?
            </SubtitleContainer>
          )}
          {screenType === "desktop" ? (
            <a href="#">
              <img src={demo} />
            </a>
          ) : (
            <a href="#">
              <DemoImage src={demoTablet} screenType={screenType} />
              <ContainerButton screenType={screenType}>
                <DxcButton label="Launch demo" size="large"></DxcButton>
              </ContainerButton>
            </a>
          )}
        </DemoContainer>
      )}
    </React.Fragment>
  );
};

const DemoContainer = styled.div`
  ${(props) =>
    props.screenType === "tablet"
      ? "margin-top: 100px; position: relative;"
      : ""};
  ${(props) => (props.screenType === "mobile" ? "position: relative;" : "")};
  a {
    display: block;
    ${(props) => (props.screenType === "mobile" ? "margin-top: 40px" : "")};
    img {
      width: 100%;
    }
  }
`;

const SubtitleContainer = styled.p`
  font-size: 20px;
  color: #66666673;
`;

const DemoImage = styled.img`
  width: 100%;
`;

const ContainerButton = styled.div`
  position: absolute;
  right: calc(50% - 120px);
  top: ${(props) =>
    props.screenType === "mobile"
      ? "calc(63% - 21.5px);"
      : "calc(54% - 21.5px);"};
`;

export default Demo;
