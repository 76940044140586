import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DxcSpinner } from "@dxc-technology/halstack-react";
import { Route, Switch } from "react-router-dom";
import { getApiCatalog } from "./../../services/api-catalog";
import SearchApis from "../../components/apis/SearchApis";
import ListApis from "../../components/apis/ListApis";
import ApiDetails from "../../components/apis/ApiDetails";
import SessionContext from "../../SessionContext.js";
import ApiCatalogContext from "../../ApiCatalogContext";

const ApiCatalog = () => {
  const history = useHistory();
  const { search } = useLocation();
  const session = useContext(SessionContext);

  const [catalog, setCatalog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { searchString, setSearchString } = useContext(ApiCatalogContext);
  const { selectedRegion, setSelectedRegion } = useContext(ApiCatalogContext);

  useEffect(() => {
    setIsLoading(true);
    getApiCatalog()
      .then((catalog) => {
        setCatalog(catalog);
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        setCatalog(null);
        setIsLoading(false);
        setError(err);
      });
  }, []);

  const params = new URLSearchParams(search);
  const selectedIndustry = params.get("industry");
  const selectedDomain = params.get("domain");

  const select = useCallback(
    ({ industry, domain, api }) => {
      history.push(
        (industry &&
          domain &&
          api &&
          `/apis/catalog/${industry}/${domain}/${api}?domain=${domain}&industry=${industry}`) ||
          (industry &&
            domain &&
            !api &&
            `/apis/catalog?domain=${domain}&industry=${industry}`) ||
          (industry &&
            !domain &&
            !api &&
            `/apis/catalog?industry=${industry}`) ||
          "/apis/catalog"
      );
    },
    [history]
  );

  const onChangeSearchString = ({ value }) => {
    setSearchString(value);
    select({
      industry: null,
      domain: null,
      api: null,
    });
  };

  const selectRegion = ({ value }) => {
    setSelectedRegion(value);
    select({
      industry: null,
      domain: null,
      api: null,
    });
  };

  return (
    <React.Fragment>
      {isLoading && !session.isFetchingSession && (
        <DxcSpinner margin="medium" label="Loading APIS" mode="overlay" />
      )}
      <SearchApis
        searchString={searchString}
        selectedRegion={selectedRegion}
        onChangeSearchString={onChangeSearchString}
        selectRegion={selectRegion}
      />
      <Switch>
        <Route exact path="/apis/catalog">
          <ListApis
            catalog={catalog}
            selectedIndustry={selectedIndustry}
            selectedDomain={selectedDomain}
            searchString={searchString}
            selectedRegion={selectedRegion}
            select={select}
          />
        </Route>
        <Route path="/apis/catalog/:industry/:domain/:api">
          <ApiDetails catalog={catalog} select={select} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ApiCatalog;
