import axios from "axios";

export function getComponentsCatalog() {
  return axios({
    method: "GET",
    url: `https://developer.dxc.com/guidelines/catalog/catalog.json`,
    responseType: "json",
  })
    .then((response) => response.data)
    .catch((e) => console.error(e));
}

export function getComponentsArray(catalog) {
  const arr = [];
  catalog.forEach((el) => arr.push(el.name));
  return arr;
}

export function getVersion() {
  return axios({
    method: "GET",
    url: `https://registry.npmjs.org/@dxc-technology/halstack-react/latest`,
    responseType: "json",
  })
    .then((response) => extractMajorVersion(response.data.version))
    .catch((e) => console.error(e));
}

function extractMajorVersion(version) {
  // Parse the version string and extract the major version
  const majorVersion = parseInt(version.split(".")[0], 10);
  // Ensure that the parsed value is a valid number
  return isNaN(majorVersion) ? null : majorVersion;
}
