import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  DxcTextInput,
  DxcFlex,
  DxcSpinner,
  DxcHeading,
} from "@dxc-technology/halstack-react";
import { useScreenType } from "../../common/utils";
import {
  getComponentsCatalog,
  getComponentsArray,
  getVersion,
} from "../useCatalog";
import CardComponent from "./CardComponent";

const ComponentGallery = () => {
  const screenType = useScreenType();
  const [componentNames, setComponentNames] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [filteredCatalog, setFilteredCatalog] = useState([]);
  const [version, setVersion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sizeInput, setSizeInput] = useState("large");

  useEffect(() => {
    setIsLoading(true);
    getComponentsCatalog()
      .then((catalog) => {
        setCatalog(catalog);
        setFilteredCatalog(catalog);
        const components = getComponentsArray(catalog);
        setComponentNames(components);
        setIsLoading(false);
      })
      .catch((err) => {
        setCatalog(null);
        setComponentNames(null);
        setIsLoading(false);
        setError(error);
      });
    getVersion()
      .then((version) => {
        setVersion(version);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (screenType === "mobile") setSizeInput("small");
    else if (screenType === "tablet") setSizeInput("medium");
    else setSizeInput("large");
  }, [screenType]);

  const onChange = ({ value }) => {
    let array = catalog.filter((option) =>
      option.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredCatalog(array);
  };

  const callbackFunc = (newValue) => {
    const result = new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          newValue
            ? componentNames.filter((option) =>
                option.toLowerCase().startsWith(newValue.toLowerCase())
              )
            : componentNames
        );
      }, 200)
    );
    return result;
  };

  return (
    <ComponentsSectionContainer screenType={screenType}>
      {isLoading && (
        <DxcSpinner margin="medium" label="Loading..." mode="overlay" />
      )}
      <ComponentsContainer screenType={screenType}>
        <DxcFlex direction="column" gap="20px">
          <DxcHeading level={1} text="Components" />
          <DxcHeading
            level={2}
            text="Explore our extensive catalog and take your User Experience to the next level."
          />
        </DxcFlex>
        <DxcFlex direction="column" gap="70px" alignItems="center">
          <DxcTextInput
            placeholder="Search component"
            onChange={onChange}
            suggestions={callbackFunc}
            size={sizeInput}
            clearable
          />
          <CardContainer>
            {filteredCatalog.map((row, index) => (
              <CardComponent
                component={row}
                version={version}
                key={`component-${index}`}
              />
            ))}
          </CardContainer>
        </DxcFlex>
      </ComponentsContainer>
    </ComponentsSectionContainer>
  );
};

const ComponentsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: ${(props) =>
    props.screenType === "tablet"
      ? "0 16%"
      : props.screenType === "mobile"
      ? "0 40px"
      : "0 12%"};
  margin-top: ${(props) =>
    props.screenType === "tablet"
      ? "80px"
      : props.screenType === "mobile"
      ? "60px"
      : "119px"};
  margin-bottom: ${(props) =>
    props.screenType === "tablet"
      ? "60px"
      : props.screenType === "mobile"
      ? "36px"
      : "120px"};
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  ${(props) => (props.screenType !== "desktop" ? "width: 100%;" : "")};
`;

const CardContainer = styled.div`
  display: grid;
  grid-gap: 2rem;

  @media (min-width: 20rem) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 45rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 66rem) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 90rem) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default ComponentGallery;
