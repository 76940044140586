import React, { useState, useEffect, useContext } from "react";
import HalUI from "@diaas/hal-ui";
import styled from "styled-components";
import SetUpApiKey from "./SetUpApiKey";
import SessionContext from "../../SessionContext.js";

const HalSpec = ({ api }) => {
  const [apiTitle, setApiTitle] = useState("");

  const session = useContext(SessionContext);

  useEffect(() => {
    api && setApiTitle(api.info.title);
  }, [api]);

  const formURLFromAPISpec = (swagger) => {
    return `${swagger.schemes}://${swagger.host}${swagger.basePath}`;
  };

  const getAllHeaders = (api) => {
    return { ...getHeadersFromApiSpec(api), "x-api-key": session.apiKey };
  };

  const getHeadersFromApiSpec = (api) => {
    return (api && api.definitions && api.definitions.headers) || {};
  };

  return session.apiKey && !session.isFetchingSession ? (
    <HalUIContainer>
      <HalUI headers={getAllHeaders(api)} url={formURLFromAPISpec(api)} />
    </HalUIContainer>
  ) : (
    !session.apiKey && !session.isFetchingSession && (
      <SetUpApiKey apiTitle={apiTitle} onSetApiKey={session.setApiKey} />
    )
  );
};

export default HalSpec;

const HalUIContainer = styled.div`
  & > div > h1 {
    font-size: 20px;
    margin-top: 20px;
  }
`;
