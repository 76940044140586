import React, { useMemo } from "react";
import styled from "styled-components";
import { getFeaturedList } from "./../../services/api-catalog";
import FeaturedApis from "./FeaturedApis";
import AllApis from "./AllApis";
import { useScreenType } from "../../common/utils";

const ListApis = ({
  catalog,
  selectedIndustry,
  selectedDomain,
  searchString,
  selectedRegion,
  select,
}) => {
  const screenType = useScreenType();

  const featuredApis = useMemo(() => getFeaturedList(catalog), [catalog]);

  return (
    <ListApisContainer screenType={screenType}>
      <FeaturedApis featuredApis={featuredApis} select={select} />
      <AllApis
        catalog={catalog}
        selectedIndustry={selectedIndustry}
        selectedDomain={selectedDomain}
        searchString={searchString}
        selectedRegion={selectedRegion}
        select={select}
      />
    </ListApisContainer>
  );
};

const ListApisContainer = styled.div`
  margin-right: ${(props) => (props.screenType === "desktop" ? "6%" : "")};
  margin-left: ${(props) => (props.screenType === "desktop" ? "10%" : "")};
  min-height: 800px;
`;

export default ListApis;
