import { responsiveSizes } from "./variables";
import { useState, useEffect, useContext } from "react";
import {
  getApiCatalog,
  getApiResource,
  getApisFromCatalog,
} from "../services/api-catalog";
import SessionContext from "../SessionContext.js";
import { getStatus, formURLFromAPISpec } from "../services/api-status";

export const useScreenType = () => {
  const [screenType, setScreenType] = useState();

  const handleResize = () => {
    let type = "desktop";

    if (
      document.documentElement.clientWidth > responsiveSizes.tablet &&
      document.documentElement.clientWidth <= responsiveSizes.desktop
    ) {
      type = "laptop";
    } else if (
      document.documentElement.clientWidth > responsiveSizes.mobileLarge &&
      document.documentElement.clientWidth <= responsiveSizes.tablet
    ) {
      type = "tablet";
    } else if (
      document.documentElement.clientWidth <= responsiveSizes.mobileLarge
    ) {
      type = "mobile";
    }
    setScreenType(type);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenType;
};

export const checkIsResponsive = () => {
  return window.innerWidth < responsiveSizes.laptop;
};

export const isResponsiveMobile = () => {
  return window.innerWidth <= responsiveSizes.mobileLarge;
};

export const isResponsiveTablet = () => {
  return (
    window.innerWidth > responsiveSizes.mobileLarge &&
    window.innerWidth <= responsiveSizes.tablet
  );
};

export const useApiStatus = () => {
  const [catalog, setCatalog] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { apiKey, setApiKey } = useContext(SessionContext);

  const filteredCatalog = catalog.filter((api) => {
    if (searchValue.value) {
      return api.title
        .toLowerCase()
        .includes(
          searchValue.value
            ? searchValue.value.toLowerCase()
            : searchValue?.toLowerCase()
        );
    } else return catalog;
  });

  useEffect(() => {
    if (apiKey) {
      setIsLoading(true);
      getApiCatalog().then((response) => {
        const apis = getApisFromCatalog(response).map((api) => ({
          ...api,
          status: "loading",
        }));
        setCatalog(apis);
        setIsLoading(false);

        apis.forEach(async (api) => {
          const spec = await getApiResource(api, "spec", "json");
          const status = await getStatus(
            spec,
            formURLFromAPISpec(spec),
            apiKey
          );
          setCatalog((oldCatalog) =>
            oldCatalog.map((oldApi) =>
              areSameApi(oldApi, api) ? { ...oldApi, status } : oldApi
            )
          );
        });
      });
    }
  }, [apiKey]);

  return {
    catalog,
    setCatalog,
    searchValue,
    setSearchValue,
    isLoading,
    setIsLoading,
    apiKey,
    setApiKey,
    filteredCatalog,
  };
};

export const areSameApi = (api1, api2) =>
  api1.key === api2.key &&
  api1.domainKey === api2.domainKey &&
  api1.industryKey === api2.industryKey;
